import { UserRepository } from './repository'

export default {
  /**
   * Delete salesman
   */
  async DELETE_USER({}, payload) {
    await UserRepository.deleteUser(payload)
  },

  /**
   * Get titles
   */
  async GET_TITLES_LIST({ commit }) {
    const titles = await UserRepository.getTitles()
    const secondTitles = await UserRepository.getSecondTitles()
    commit('SET_TITLE_OPTIONS', titles)
    commit('SET_SECOND_TITLE_OPTIONS', secondTitles)
  },

  /**
   * Get mottos
   */
  async GET_MOTTOS_LIST({ commit }) {
    const mottos = await UserRepository.getMottos()
    commit('SET_MOTTO_OPTIONS', mottos)
  },

  /**
   * Get positions
   */
  async GET_POSITIONS_LIST({ commit }) {
    const positions = await UserRepository.getPositions()
    commit('SET_POSITION_OPTIONS', positions)
  }
}
