import state, { getDefaultState } from './state'
import actions from './actions'
import mutations from './mutations'

import { make } from 'vuex-pathify'

export default {
  namespaced: true,
  getters: {
    ...make.getters(state)
  },
  mutations: {
    ...mutations,
    ...make.mutations(state),
    RESET(state) {
      Object.assign(state, getDefaultState())
    }
  },
  actions,
  state
}
