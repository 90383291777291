export default {
  user: null,
  shop: null,

  salesman: null,
  userId: null,

  productLoves: [],
  goodyLoves: [],
  pressArticleLoves: [],

  paymentModes: [],
  currentPaymentMode: null,
  accessToken: null,
  refreshToken: null,
  expiry: null,
  lastRefresh: null
}
