export class TutorialRepository {
  static setScriptTutorialDone(script) {
    localStorage.setItem('scriptsComplete', JSON.stringify(script))
  }
  static tutorialAbort() {
    localStorage.setItem('disableTutorial', true)
  }
  static getDisableTutorial() {
    return localStorage.getItem('disableTutorial') == 'true'
  }

  static getScriptsDone() {
    if (
      localStorage.getItem('scriptsComplete') &&
      localStorage.getItem('scriptsComplete') !== '[object Object]'
    )
      return JSON.parse(localStorage.getItem('scriptsComplete'))
    return null
  }
  static tutorialReset() {
    localStorage.setItem('disableTutorial', false)
    localStorage.setItem('scriptsComplete', {})
  }
}
