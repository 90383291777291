import * as Sentry from '@sentry/vue'

export default ({ Vue, router, store }) => {
  Sentry.init({
    Vue,
    dsn: 'https://1838156b36fe4ff9b9c0587a8e08eae7@glitchtip.owlie.xyz/1',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      })
    ],
    tracesSampleRate: 1.0,
    environment: process.env.ENV,
    autoSessionTracking: false
  })
}
