import { $http } from 'boot/axios'
export class FilesRepository {
  /**
   * Create shop file
   * @param {} payload
   */
  static async createShopFile(payload) {
    let fd = new FormData()
    fd.append('key', payload.key)
    fd.append('shopId', payload.shopId)

    return $http.post(`/shopFiles`, fd, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }

  /**
   * Delete shop file
   * @param {} payload
   */
  static async deleteShopFile(payload) {
    const { id } = payload

    return $http.delete(`/shopFiles/${id}`)
  }

  /**
   * Create user + profile file
   * @param {} payload
   */
  static async createUserFile(payload) {
    let fd = new FormData()
    fd.append('key', payload.key)
    fd.append('userId', payload.userId)

    return $http.post(`/userFiles`, fd, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }

  /*
   * @param {} payload
   */
  static async deleteUserFile(payload) {
    const { id } = payload;
    try {
      const responseGet = await $http.get(`/userFiles/${id}`);
      if (responseGet?.id) {
        // File exists, lets delete
        await $http.delete(`/userFiles/${id}`);
        return { success: true, message: 'File deleted successfully' };
      } else {
        return { success: false, message: 'Failed to delete file' };
      }
    } catch (error) {
      console.error('error:', error);
      return { success: false, message: 'An error occurred' };
    }
  }
  // static async deleteUserFile(payload) {
  //   const { id } = payload
  //   return $http.delete(`/userFiles/${id}`)
  // }


}
