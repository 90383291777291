import { make } from 'vuex-pathify'
import state from './state.js'
import getters from './getters'
import actions from './actions.js'

export default {
  namespaced: true,
  getters: {
    ...make.getters(state),
    ...getters
  },
  mutations: {
    ...make.mutations(state)
  },
  state,
  actions
}
