import errors from './global/errors'

export default {
  layout: {
    shops: 'Boutique(s)',
    paymentModes: 'Paiements',
    orders: 'Commandes',
    remainder: 'Reliquats',
    points: 'Cubes',
    salesman: 'Commercial'
  },

  edit: {
    edition: 'Éditer le profil',
    lastName: 'Nom',
    firstName: 'Prénom',
    bornAt: 'Anniversaire',
    email: 'Email',
    mobilePhone: 'Téléphone',
    title: 'Titre',
    secondTitle: 'Titre 2',
    motto: 'Devise',
    position: 'Poste',
    mainShop: 'Boutique',
    cancel: 'Annuler',
    submit: 'Enregistrer mon profil',
    submitXs: 'Enregistrer',
    success: 'Les données utilisateur ont bien été mises à jour.',
    supportForName: 'Pour modifier le nom de votre boutique merci de contacter notre service client par email: support@tribuo.fr',
    errors: {
      email: 'Cet Email est déjà pris',
      E00017: 'Cet Email est déjà pris'
    }
  },

  paymentModes: {
    paymentModes: 'Mes moyens de paiement',
    noPaymentMode: "Aucun moyen de paiement n'est rattaché pour l'instant.",
    bankTransfer: 'Virement bancaire',
    bankDirectDebit: 'Prélèvement bancaire',
    billOfExchange: 'Traiter auto LCR',
    card: 'Carte bancaire',

    edit: 'Modifier',
    delete: 'Supprimer',

    deleteMessage: 'Êtes-vous sûr.e.s de vouloir supprimer ce moyen de paiement ?',

    editPaymentMode: 'Modifier le moyen de paiement',
    deletePaymentMode: 'Supprimer le moyen de paiement',
    addPaymentMode: 'Ajouter un moyen de paiement',

    form: {
      name: 'Nom',
      type: 'Type',
      iban: 'IBAN',
      bic: 'Code BIC',

      shop: 'Boutique',
      addressLine1: 'Adresse',
      zipCode: 'Code postal',
      city: 'Commune',
      country: 'Pays',

      errors: {
        name: 'Nom requis',
        shopId: `Sélection d'une boutique requise`,
        type: 'Type requis',
        ibanOrBicInvalid: 'Une de vos données bancaires est invalide',
        iban: 'IBAN requis',
        bic: 'BIC obligatoire',

        addressLine1: 'Adresse requise',
        zipCode: 'Code postal requis',
        city: 'Commune requise',
        country: 'Pays requis'
      },

      success: 'Moyen de paiement ajouté avec succès',

      actions: {
        add: 'Ajouter',
        edit: 'Modifier',
        delete: 'Supprimer',
        cancel: 'Annuler',
        ok: 'Valider'
      }
    }
  },

  gamification: {
    balance: 'Votre solde actuel',
    points: 'cubes',
    coupons: 'Mes coupons',
    earnPoint: 'Comment gagner des cubes ?',
    howBuyCoupons: 'Comment dépenser des cubes ?',
    reducGoodies: 'Achat de goodies',
    buyGoodies: 'Achat de coupons',
    buyCoupons: 'Dépenser ses cubes',
    noneCoupons: `vous n'avez actuellement aucun coupon`
  },

  roles: {
    manager: 'Gérant',
    seller: 'Vendeur'
  },

  salesman: {
    noData: `Votre boutique principale n'est pas encore rattachée à un commercial.`,
    mobilePhone: 'Mobile',
    officePhone: 'Bureau',
    sendMail: 'Envoyer un email',
    call: 'Appeler',
    meet: 'Demander un rendez-vous'
  }
}
