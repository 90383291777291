import Vue from 'vue'
export default {
  RESET_ORDERING_INFOS (state) {
    state.orderingInfos = {
      shopId: null,
      shippingModeId: null,
      paymentModeId: null
    }
  },
  RESET_ORDERING_OPTIONS (state) {
    state.orderingOptions = {
      shipmentInstructions: '',
      requestedFor: null
    }
  },
  SET_ORDERDETAILSBYID (state, orderDetails) {
    const orderIndex = state.orders.findIndex((order) => order.id === orderDetails.id)
    orderDetails.productOrders = orderDetails.productOrders.map((product) => {
      return {
        ...product, ...{ quantity: { classic: product.quantity } }
      }
    })
    Vue.set(state.orders, orderIndex, orderDetails)
  }
}
