import { $http } from 'boot/axios'

import mondaySdk from 'monday-sdk-js'
const mondaySDK = mondaySdk()
const configMonday = {
  //token, used to do queries and mutations
  mondayApiToken: process.env.MONDAY.API_KEY,
  //id of the "SAV" board
  // TODO SAV : board id to change for the official one (we are currently testing in a copy named "Owlie - Copie de SAV")
  boardId: process.env.MONDAY.BOARD_ID,
  //id of the "Problème" group in the "SAV" board
  groupId: 'nouveau_groupe',
  //label before displaying the reason
  reasonLabel: 'Motif du SAV : ',
  //label before displaying the details
  detailsLabel: '\nDétails: '
}

export class savRepository {
  /**
   *
   * @param {*} payload
   * @returns
   */
  static async saveSAVRequest(payload, address) {
    await $http.post(`/orders/returns`, payload)
    /*
    const entries = payload.entries

    //first, set token
    mondaySDK.setToken(configMonday.mondayApiToken)

    //then, query
    //sending one SAV entry to the right board, and inserting a description
    const sendOneSAVEntry = async (entry, boardId, groupId, monday) => {
      //first, creating an item (with the board id, group id, item name and values)
      let apiMutation = `mutation {
          create_item (
            board_id: ${boardId},
            group_id: "${groupId}",
            item_name: "${entry.productName}",
            column_values: "{
              \\"long_texte\\": \\"${entry.part}\\",
              \\"texte\\": \\"${address.name}\\",
              \\"texte1\\": \\"${address.addressLine1}\\",
              \\"texte08\\": \\"${address.zipCode}\\",
              \\"texte3\\": \\"${address.city}\\",
              \\"texte9\\": \\"${address.email}\\",
              \\"t_l_phone\\": \\"${address.phone}\\",
              \\"origine_du_probl_me\\": \\"${entry.reason}\\"
            }")
            {
            id
          }
        }`
      const createItemResponse = await monday.api(apiMutation)

      //mutation didnt work
      if (createItemResponse.errors) {
        throw createItemResponse.errors
      }
      //mutation didnt create an item
      if (!createItemResponse?.data?.create_item?.id) {
        throw 'error: no id in the result of the create item response'
      }

      //then, updating the item just created,
      //(used to add the conversation on this item)
      const itemJustCreatedId = createItemResponse.data.create_item.id
      let apiConvoMutation = `
          mutation {
            create_update (item_id: ${itemJustCreatedId},
              body: "${configMonday.reasonLabel}${entry.reason}${configMonday.detailsLabel}${entry.details}") {
              id
            }
          }
        `
      const createUpdateResponse = await monday.api(apiConvoMutation)

      //mutation didnt work
      if (createUpdateResponse.errors) {
        throw createUpdateResponse.errors
      }
    }

    try {
      //sending every entries
      for (const prop in entries) {
        await sendOneSAVEntry(
          entries[prop],
          configMonday.boardId,
          configMonday.groupId,
          mondaySDK
        ).catch(e => {
          // error has been catched, aborting
          throw e
        })
      }
      return
    } catch (err) {
      return err
    }
    */
  }
}
