import { ProductsRepository } from './repository'
import {
  changeProductsPricesFromPricelist,
  changeProductPriceFromPriceList
} from '../../utils/products.js'
import { differenceInDays } from 'date-fns'
import coupons from '../coupons'
export default {
  /**
   * Load products
   */
  async LOAD_PRODUCTS({ dispatch, commit, state, rootState }) {
    const { filters, frontFilters } = rootState.filters

    const { limit } = state.pagination
    filters.loved = frontFilters.likes
    const frontFiltersArray = Object.entries(frontFilters)
    const activeFilterIndex = frontFiltersArray.findIndex(filter => {
      if (filter[1]) return true
    })
    const labeledFilters = ['precommande', 'nouveau', 'goodPlans']
    if (
      activeFilterIndex >= 0 &&
      labeledFilters.includes(frontFiltersArray[activeFilterIndex][0])
    ) {
      filters.label = frontFiltersArray[activeFilterIndex][0]
    } else {
      filters.label = null
    }
    const orderBy = 'releaseAt'
    const orderWay = 'desc'
    if (filters.loved) filters.userId = rootState.auth.userId
    const payload = { limit, ...filters, orderBy, orderWay }
    let products = await ProductsRepository.loadProducts(payload)
    if (products.products.length) {
      const pricingList = await dispatch('LOAD_PRICINGLIST', products)
      products = changeProductsPricesFromPricelist(products, pricingList)
      products.products = products.products.map(product => {
        return { ...product, prodType: 'product' }
      })
    }
    commit('SET_TOTAL_PRODUCTS', products.total)
    commit('SET_PRODUCTS', products)
  },

  /**
   * Load more products
   */
  async LOAD_MORE_PRODUCTS({ commit, dispatch, state, rootState }, index) {
    const { filters, frontFilters } = rootState.filters
    const limit = 20
    const offset = index > 0 ? limit * index : 0
    filters.loved = frontFilters.likes
    const frontFiltersArray = Object.entries(frontFilters)
    const activeFilterIndex = frontFiltersArray.findIndex(filter => {
      if (filter[1]) return true
    })
    const labeledFilters = ['precommande', 'nouveau', 'promotion']
    if (
      activeFilterIndex >= 0 &&
      labeledFilters.includes(frontFiltersArray[activeFilterIndex][0])
    ) {
      filters.label = frontFiltersArray[activeFilterIndex][0]
    } else {
      filters.label = null
    }
    const payload = { limit, offset, ...filters, orderBy: 'releaseAt', orderWay: 'desc' }

    let products = await ProductsRepository.loadProducts(payload)
    if (products.products.length) {
      products.products = products.products.map(product => {
        return { ...product, prodType: 'product' }
      })
      const pricingList = await dispatch('LOAD_PRICINGLIST', products)
      products = changeProductsPricesFromPricelist(products, pricingList)
    }
    commit('SET_TOTAL_PRODUCTS', products.total)
    if (state.products.products && state.products.products.length <= products.total) {
      commit('SET_MORE_PRODUCTS', products.products)
    } else {
      commit('SET_PRODUCTS', products)
    }
    return products
  },

  /**
   *
   * Get current Pricing List for current shop
   */
  async LOAD_PRICINGLIST({ commit }, products) {
    if (products.products && products.products.length) {
      const productsIds = products.products.map(product => product.zohoId)
      const pricingList = await ProductsRepository.loadPricingList(productsIds)
      commit('PUSH_PRICINGLIST', pricingList)
      return pricingList
    } else {
      return null
    }
  },

  /**
   *
   */
  async LOAD_PRODUCTS_FOR_SAV({ commit, state, rootState }) {
    const { filtersSav } = rootState.filters
    const { limit, offset } = state.paginationSav

    const payload = { limit, offset, ...filtersSav }

    const products = await ProductsRepository.loadProducts(payload)
    commit('SET_TOTAL_PRODUCTS_SAV', products.total)
    commit('SET_PRODUCTS_SAV', products.products)
  },

  /**
   * Save SAV request
   *
   */
  async SAVE_SAV_REQUEST({}, entries) {
    return ProductsRepository.saveSAVRequest(entries)
  },

  /**
   * Load current product
   */
  async LOAD_PRODUCT({ commit, dispatch, rootState }, id) {
    const product = await ProductsRepository.loadProduct(id)
    const availableOffers = await ProductsRepository.loadProductOffers({
      id: id,
      shopId: rootState.auth.user.shops[0].id
    })
    const pricingList = await dispatch('LOAD_PRICINGLIST', { products: [product] })
    const productFinal = changeProductPriceFromPriceList(product, pricingList)
    if (product.quiz) {
      product.quiz.questions = product.quiz.questions
        .map(question => {
          question.responses = question.responses.sort((a, b) => a.position - b.position)
          return question
        })
        .sort((a, b) => a.position - b.position)
    }

    commit('SET_PRODUCT', {
      ...productFinal,
      ...{ availableOffersArray: availableOffers },
      prodType: 'product'
    })
    return productFinal
  },

  /**
   * Load current product
   */
  async LOAD_GOODIE({ commit, dispatch }, id) {
    const goodie = await ProductsRepository.loadGoodie(id)
    const pricingList = await dispatch('LOAD_PRICINGLIST', { products: [goodie] })
    const goodieFinal = changeProductPriceFromPriceList(goodie, pricingList)
    commit('SET_PRODUCT', { ...goodieFinal, prodType: 'goody' })
  },
  /**
   * Load product with return
   */
  async LOAD_PRODUCT_RETURNED({ rootState, dispatch }, { id, type }) {
    let product
    if (type === 'product') {
      product = await ProductsRepository.loadProduct(id)
      const availableOffersArray = await ProductsRepository.loadProductOffers({
        id: id,
        shopId: rootState.auth.user.shops[0].id
      })
      product = { ...product, ...{ availableOffersArray } }
    } else product = await ProductsRepository.loadGoodie(id)
    const pricingList = await dispatch('LOAD_PRICINGLIST', { products: [product] })
    const productFinal = changeProductPriceFromPriceList(product, pricingList)
    return { ...productFinal }
  },

  /**
   * Load goodies
   */
  async LOAD_GOODIES({ rootState, state, commit, dispatch }, index) {
    if (index === undefined) return
    const limit = 20
    const offset = index > 0 ? limit * index : 0
    const { filters, frontFilters } = rootState.filters
    filters.loved = frontFilters.likes
    if (filters.loved) filters.userId = rootState.auth.userId
    const payload = { limit, offset, orderByDesc: 'releaseAt', ...filters }
    let goodies = await ProductsRepository.loadgoodies(payload)
    if (goodies.products?.length) {
      const pricingList = await dispatch('LOAD_PRICINGLIST', { products: goodies.products })
      goodies = changeProductsPricesFromPricelist(goodies, pricingList)
      goodies.products = goodies.products.map(goodie => {
        return { ...goodie, prodType: 'goody' }
      })
      if (state.goodies.products && index > 0) {
        commit('SET_MORE_GOODIES', goodies.products)
      } else {
        commit('SET_GOODIES', goodies)
      }
    }
  },

  /**
   *
   * @param {*} param0
   */
  async LOAD_LAST_BASKET({ commit, dispatch, rootState, state }) {
    if (!rootState.auth.user) return
    const { id: userId } = rootState.auth.user
    const call = await ProductsRepository.loadLastBasket(userId)
    if (!call) return
    else if (Array.isArray(call) && rootState.products.cart.length) {
      await dispatch('RESET_BASKET')
    }
    let { cart, id } = call
    if (!cart || !Array.isArray(cart)) return false
    let finalCart = []
    let toDeleteFromCart = []
    let toQuantityUpdated = []
    let toReliquate = []
    cart = cart.filter(item => !item.hasError)
    if (cart && cart.length && cart.length > 0) {
      for (let i = 0; i < cart.length; i++) {
        if (cart[i].prodType === 'coupon') {
          if (!rootState.coupons.couponsInCart.find(coupon => coupon.id === cart[i].id)) {
            commit('coupons/PUSH_COUPON_IN_CART', cart[i], { root: true })
          }
        } else if (cart[i].prodType === 'discoveryOffer') {
          const offers = rootState.offers.discoveryOffers
          if (offers.find(offer => offer.id == cart[i].offerId && offer.stock && offer.stock > 0)) {
            commit('offers/SET_SELECTED_OFFER', cart[i].offerId, { root: true })
          }
        } else {
          let product = await dispatch('LOAD_PRODUCT_RETURNED', {
            id: cart[i].id,
            type: cart[i].prodType
          })
          product.quantity = cart[i].quantity
          let totalQuantity = 0
          if (product.quantity) {
            Object.entries(product?.quantity).forEach(([key, quantity]) => {
              if (key !== 'classic' && !product.offers?.some(offer => offer.type === key)) {
                console.warn(
                  `L'offre ${key} n'existe pas dans les offres disponibles pour ${product.name}`
                )
                delete product.quantity[key]
              } else {
                totalQuantity += parseInt(quantity)
              }
            })
            if (
              product.stock >= 0 ||
              (((cart[i].prodType === 'product' && product.stock < totalQuantity) ||
                (cart[i].prodType === 'goody' &&
                  product.stock < product.quantity.euros + product.quantity.cubes)) &&
                (!product.restockDate ||
                  (product.restockDate &&
                    differenceInDays(new Date(product.restockDate), new Date()) >= 21)))
            )
              if (
                product.stock <= 0 &&
                (!product.restockDate ||
                  (product.restockDate &&
                    differenceInDays(new Date(product.restockDate), new Date()) >= 21))
              ) {
                toDeleteFromCart.push(product)
              } else if (totalQuantity > product.stock) {
                if (
                  product.restockDate &&
                  differenceInDays(new Date(product.restockDate), new Date()) <= 21
                ) {
                  product.reliquateQuantity = totalQuantity - parseInt(product.stock)
                  toReliquate.push(product)
                } else {
                  if (cart[i].prodType === 'product') {
                    toQuantityUpdated.push({ id: product.id, name: product.name })
                    product.quantity.classic = product.stock
                    Object.entries(product.quantity).forEach(([quantityType, quantity]) => {
                      if (quantityType !== 'classic') {
                        product.quantity.classic = product.quantity.classic - parseInt(quantity)
                      }
                      if (quantity <= 0) delete product.quantity[quantityType]
                    })
                  } else {
                    // TODO STOCK OF GOODIES
                  }
                }
              }
            if (
              (cart[i].prodType === 'product' && product.quantity.classic > 0) ||
              (cart[i].prodType === 'goody' &&
                (product.quantity.euros > 0 || product.quantity.cubes > 0))
            ) {
              finalCart.push({
                ...product,
                ...{ prodType: cart[i].prodType, addedToCartDate: cart[i].addedToCartDate }
              })
            } else {
              commit('SET_HAS_BASKET_CHANGED', true)
            }
          }
        }
      }
    } else {
      commit('SET_CART', [])
      commit('SET_HAS_BASKET_CHANGED', true)
    }
    console.log('finalCart', finalCart)
    if (finalCart?.length) {
      commit('SET_CART', finalCart)
      commit('SET_LAST_BASKET_ID', id)
      finalCart.forEach(product => {
        const oldProductQuantity = JSON.stringify(product.quantity)
        if (product.prodType === 'product') commit('UPDATE_PRODUCT_OFFERS', product)
        commit('SET_HAS_BASKET_CHANGED', oldProductQuantity != JSON.stringify(product.quantity))
      })
      if (state.hasBasketChanged) {
        await dispatch('SAVE_BASKET')
        commit('SET_HAS_BASKET_CHANGED', false)
      }
    }
    if (toQuantityUpdated?.length) {
      commit('SET_QUANTITY_UPDATED_FROM_CART', toQuantityUpdated)
      commit('SET_HAS_BASKET_CHANGED', true)
    }
    if (toDeleteFromCart?.length) {
      commit('SET_DELETED_FROM_CART', toDeleteFromCart)
      commit('SET_HAS_BASKET_CHANGED', true)
    }
  },

  /**
   *
   * @param {*} param0
   */
  async SAVE_BASKET({ state, commit, rootState }, force = false) {
    if (!rootState.auth.user) return
    const { id: userId } = rootState.auth.user
    let coupons = rootState.coupons.couponsInCart
    if (coupons.length) {
      coupons = coupons.map(coupon => {
        return { ...coupon, prodType: 'coupon' }
      })
    }
    const { lastBasketId, hasBasketChanged } = state
    if (!hasBasketChanged && !force) return
    const discoverOffer = rootState.offers.selectedOffer
    const products = state.cart.reduce((acc, prod) => {
      if (
        (prod.prodType == 'product' && prod.quantity.classic > 0) ||
        (prod.prodType === 'goody' && (prod.quantity.euros > 0 || prod.quantity.cubes > 0))
      )
        acc.push({
          productId: prod.id,
          quantity: prod.quantity,
          prodType: prod.prodType,
          addedToCartDate: prod.addedToCartDate
        })
      return acc
    }, [])
    const content = [...products, ...coupons]
    if (discoverOffer) {
      content.push({ offerId: discoverOffer, prodType: 'discoveryOffer' })
    }
    try {
      const { id } = await ProductsRepository.saveBasket({ userId, id: lastBasketId, content })
      commit('SET_LAST_BASKET_ID', id)
    } catch (e) {
      const { id } = await ProductsRepository.loadLastBasket(userId)
      await ProductsRepository.saveBasket({ userId, id, content })
      commit('SET_LAST_BASKET_ID', id)
    }
    commit('SET_HAS_BASKET_CHANGED', false)
  },

  /**
   * Add a product to the cart
   */
  async ADD_PRODUCT_TO_CART({ commit, dispatch, state }, product) {
    commit('ADD_PRODUCT_TO_CART', product)
    if (product.prodType !== 'goody') {
      const prod = state.cart.find(p => p?.id == product?.id)
      commit('UPDATE_PRODUCT_OFFERS', prod)
    }
    commit('SET_HAS_BASKET_CHANGED', true)
    await dispatch('SAVE_BASKET')
  },

  /**
   * Remove a product from the cart
   */
  async REMOVE_PRODUCT_FROM_CART({ commit, dispatch, state }, product) {
    commit('REMOVE_PRODUCT_FROM_CART', product)
    if (product.prodType !== 'goody') {
      const prod = state.cart.find(p => p?.id == product?.id)
      commit('UPDATE_PRODUCT_OFFERS', prod)
    }
    commit('SET_HAS_BASKET_CHANGED', true)
    await dispatch('SAVE_BASKET')
  },

  /**
   * Remove a product completely from the cart
   */
  async REMOVE_PRODUCT_COMPLETELY_FROM_CART({ commit, dispatch }, product) {
    commit('REMOVE_PRODUCT_COMPLETELY_FROM_CART', product)
    commit('SET_HAS_BASKET_CHANGED', true)
    await dispatch('SAVE_BASKET')
  },

  /**
   * change a product quantity from the cart
   */
  async CHANGE_QUANTITY_PRODUCT_FROM_CART({ rootState, commit, dispatch }, product) {
    const currentWeekShoppedItemsCount = rootState.products.shoppedItems[product.id]
      ? rootState.products.shoppedItems[product.id]
      : 0
    product.weekBuyLimit = product.weekBuyLimit - currentWeekShoppedItemsCount

    commit('CHANGE_QUANTITY_PRODUCT_FROM_CART', product)
    if (product.prodType !== 'goody') {
      commit('UPDATE_PRODUCT_OFFERS', product)
    }
    commit('SET_HAS_BASKET_CHANGED', true)
    await dispatch('SAVE_BASKET')
  },

  /**
   *
   */
  async RESET_BASKET({ commit, state, dispatch }) {
    commit('SET_CART', [])
    commit('SET_HAS_BASKET_CHANGED', true)
  },
  /**
   *
   */
  async RESET_BASKET_ID({ commit, state, dispatch }) {
    commit('SET_LAST_BASKET_ID', null)
  },

  /**
   * Load quiz by user
   * @param {*} param0
   * @param {*} payload
   */
  async LOAD_USER_QUIZ({ commit, rootState }, payload) {
    const userId = rootState.auth.user.id
    const { quizId } = payload

    const quiz = await ProductsRepository.loadUserQuiz({ userId, quizId })

    commit('SET_CURRENT_QUIZ_CHOICES', quiz.choices ?? [])
  },

  /**
   * Add a quiz answers
   */
  async ADD_QUIZ_ANSWERS({ commit, rootState }, payload) {
    const userId = rootState.auth.user.id
    const { quizId, choices } = payload

    const { amount } = await ProductsRepository.sendQuizAnswers({ userId, quizId, choices })

    commit('SET_CURRENT_QUIZ_CHOICES', choices)

    return amount
  },

  /**
   * Load categories
   */
  async LOAD_CATEGORIES({ commit }) {
    const categories = await ProductsRepository.loadCategories()
    commit('SET_CATEGORIES', categories)
  },

  /**
   * Load shopped items
   */
  async LOAD_SHOPPED_ITEMS({ commit, rootState }) {
    const shoppedItems = await ProductsRepository.getShoppedItems(rootState.auth.user.shops[0].id)
    commit('SET_SHOPPED_ITEMS', shoppedItems)
  }
}
