import { InvoiceCalculator } from '@owliehq/money'
import offers from '../../utils/offers'
export default {
  frontFilteredProducts: (state, getters, rootState, rootGetters) => {
    const productsLovedIds = rootGetters['auth/productLoves'].map(love => love.productId)
    const frontFilters = rootGetters['filters/frontFilters']
    if (frontFilters?.likes && state.products.products)
      return (state.products.products || []).filter(product => productsLovedIds.includes(product.id)).sort((a, b) => new Date(b.releaseAt) - new Date(a.releaseAt))
    else if (frontFilters?.goodPlans && state.products.products)
      return (state.products.products || []).filter(product => product.label).sort((a, b) => new Date(b.releaseAt) - new Date(a.releaseAt))
    return (state.products.products || []).sort((a, b) => new Date(b.releaseAt) - new Date(a.releaseAt))
  },

  goodies(state) {
    return state.goodies
  },

  frontFilteredGoodies: (state, getters, rootState, rootGetters) => {
    const productsLovedIds = rootGetters['auth/goodyLoves'].map(love => love.goodyId)
    const frontFilters = rootGetters['filters/frontFilters']
    if (state.goodies) {
      if (frontFilters?.likes) {
        return (state.goodies.products || [])
          .filter(product => productsLovedIds.includes(product.id))
          .sort((a, b) => {return new Date(b.releaseAt) - new Date(a.releaseAt)})
      } else if (frontFilters?.goodPlans) {
        return (state.goodies.products || [])
          .filter(product => product.label)
          .sort((a, b) => { return new Date(b.releaseAt) - new Date(a.releaseAt)})
      }
    }
    const goodies = state.goodies?.products || []
    goodies.sort((a, b) => { return new Date(b.releaseAt) - new Date(a.releaseAt)})
    return goodies
  },

  cartGroupByGoodies: state => {
    const goodies = state.cart.reduce((acc, prod) => {
      if (prod?.prodType !== 'goody') return acc
      acc[prod?.id] = { ...prod }
      return acc
    }, {})
    return goodies
  },
  cartGroupByGoodiesPurchasedInEuros: (state, getters) => {
    const goodies = getters.cartGroupByGoodies
    if (goodies && Object.keys(goodies).length) {
      Object.values(goodies).reduce((acc, prod) => {
        if (prod?.quantity.euros >= 1) acc[prod?.id] = { ...prod }
        else return acc
      })
    }
    return goodies
  },
  cartGroupByGoodiesPurchasedInCubes: (state, getters) => {
    const goodies = getters.cartGroupByGoodies
    Object.values(goodies).reduce((acc, prod) => {
      if (prod?.quantity.cubes >= 1) acc[prod?.id] = { ...prod }
      else return acc
    })
    return goodies
  },
  cartGroupByProducts: state =>
    state.cart.reduce((acc, prod) => {
      if (prod?.prodType === 'goody') return acc
      acc[prod?.id] = { ...prod }
      return acc
    }, {}),

  countCart: state => {
    return state.cart.reduce((acc, prod) => {
      if (prod.prodType === 'goody') {
        return acc + prod?.quantity.cubes + prod?.quantity.euros
      } else {
        Object.values(prod?.quantity).forEach(quantity => {
          acc += parseInt(quantity)
        })
        return acc
      }
    }, 0)
  },

  totalWithoutVAT: state =>
    state.cart.reduce((acc, prod) => {
      if (prod?.prodType === 'product') {
        Object.keys(prod?.quantity).forEach(quantityKey => {
          if (quantityKey === 'classic') acc += prod.price * prod.quantity.classic
          else if (Object.keys(offers).includes(quantityKey)) {
            acc +=
              prod.quantity[quantityKey] *
              (prod.price - (prod.price * offers[quantityKey].nextProductDiscountPercent) / 100)
          }
        })
        return acc
      } else if (prod?.prodType === 'goody' && prod?.quantity.euros >= 1) {
        return (acc += (prod.price || 0) * prod.quantity.euros)
      } else return (acc += 0)
    }, 0),

  totalGoodies: state =>
    state.cart.reduce((acc, prod) => {
      if (prod?.prodType == 'goody') return (acc += prod?.pricePoints * prod?.quantity.cubes)
      return (acc += 0)
    }, 0),

  totalVAT55: state => {
    return new InvoiceCalculator(
      state.cart.reduce((acc, prod) => {
        if (prod?.vat === 550) {
          if (prod?.prodType === 'goody' && prod?.quantity.euros >= 1) {
            acc.push({
              price: (prod.price || 0),
              vat: prod.vat,
              quantity: prod.quantity.euros
            })
          } else if (prod?.prodType === 'product') {
            Object.keys(prod.quantity).forEach(quantityKey => {
              let quantity, price
              if (quantityKey === 'classic') {
                price = prod.price
                quantity = prod.quantity.classic
              } else if (Object.keys(offers).includes(quantityKey)) {
                price =
                  prod.price - (prod.price * offers[quantityKey].nextProductDiscountPercent) / 100
                quantity = prod.quantity[quantityKey]
              }
              acc.push({
                price: price,
                vat: prod.vat / 10000,
                quantity: quantity
              })
            })
          }
        }
        return acc
      }, [])
    ).totalVAT
  },

  totalVAT200: state => {
    const totalProd = new InvoiceCalculator(
      state.cart.reduce((acc, prod) => {
        if (prod?.vat === 2000) {
          if (prod?.prodType === 'goody' && prod?.quantity.euros >= 1) {
            acc.push({
              price: (prod.price || 0),
              vat: prod.vat / 10000,
              quantity: prod.quantity.euros
            })
          } else if (prod?.prodType === 'product') {
            Object.keys(prod.quantity).forEach(quantityKey => {
              let quantity, price
              if (quantityKey === 'classic') {
                price = prod.price
                quantity = prod.quantity.classic
              } else if (Object.keys(offers).includes(quantityKey)) {
                price =
                  prod.price - (prod.price * offers[quantityKey].nextProductDiscountPercent) / 100
                quantity = prod.quantity[quantityKey]
              }
              acc.push({
                price: price,
                vat: prod.vat / 10000,
                quantity: quantity
              })
            })
          }
        }
        return acc
      }, [])
    ).totalVAT
    return totalProd
  },

  totalVAT: (state, getters) => {
    return getters.totalVAT55 + getters.totalVAT200
  },

  totalDiscount: (state, getters, rootState) => {
    let total = 0
    try {
      const ReducProduct = coupon => {
        const product = Object.values(getters['cartGroupByProducts']).find(
          el => el.id == coupon.productId
        )
        if (product)
          if (coupon.isPercentage) {
            return (product.price / 100) * coupon.discount
          } else return coupon.discount
        return 0
      }
      rootState.coupons.couponsInCart.forEach(element => {
        if (element.productId) {
          total += ReducProduct(element)
        } else if (element.isPercentage)
          total += ((getters['totalWithoutVAT'] / 100) * element.discount) / 100
        else {
          total += element.discount
        }
      })
    } catch (e) {
      console.error(e)
      total = 0
    }
    return total
  },

  hasDiscountOnShipping: (state, getters) => {
    return 25000 - getters['totalWithoutVAT'] <= 0
  },

  total: (state, getters) => {
    return getters.totalWithoutVAT + getters.totalVAT
  }
}
