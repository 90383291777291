import { $http } from 'boot/axios'
import { Querier } from '@owliehq/querier'
import pReduce from 'p-reduce'
import { h } from 'vuelidate/lib/vval'
export class ProductsRepository {
  static loadProductsHeadlines() {
    const url = Querier.query({ baseUrl: `/products` })
      .rawConditions({ label: 'Nouveau' })
      .populate('pictures')
      .generate()

    return $http.get(url)
  }

  // load products
  static async loadProducts(payload) {
    const products = await $http.post(`/products/search`, payload)
    return products
  }

  // load goodies
  static async loadgoodies(payload) {
    const url = Querier.query({ baseUrl: `/goodies` })
      .populate('pictures')
      .limit(payload.limit)
      .skip(payload.offset)
      .generate()
    const goodies = await $http.get(url)
    if (!goodies) return { total: 0, products: [] }
    else {
      return { total: goodies.length, products: goodies }
    }
  }

  /**
   *
   */
  static async loadProductOffers(payload) {
    const offers = await $http.post(`/products/${payload.id}/offers`, payload)
    return offers
  }

  // load current goodie
  static async loadGoodie(goodieId) {
    const url = Querier.query({ baseUrl: `/goodies/${goodieId}` })
      .populate('pictures')
      .generate()

    const goodie = await $http.get(url)
    return goodie
  }

  // load current product
  static async loadProduct(productId) {
    const url = Querier.query({ baseUrl: `/products/${productId}` })
      .populate('quiz.questions.responses')
      .populate('pictures')
      .populate('illustrators authors editors')
      .populate('skills additionalContents')
      .populate('pressArticles.pictures')
      .generate()

    const product = await $http.get(url)
    return product
  }

  /**
   *
   */
  static async loadLastBasket(userId) {
    const url = Querier.query({ baseUrl: `/baskets` })
      .rawConditions({ userId })
      .sortDesc('createdAt')
      .limit(1)
      .generate()

    return $http.get(url).then(async basket => {
      if (!basket.length) return []
      const cart = await pReduce(
        basket[0].content,
        async (result, basketLine) => {
          try {
            let productQuery
            if (basketLine.prodType === 'product')
              productQuery = Querier.query({ baseUrl: `/products/${basketLine.productId}` })
                .populate('pictures')
                .generate()
            else if (basketLine.prodType === 'goody')
              productQuery = Querier.query({ baseUrl: `/goodies/${basketLine.productId}` })
                .populate('pictures')
                .generate()

            let productBasket = {}
            if (basketLine.prodType === 'coupon' || basketLine.prodType === 'discoveryOffer') {
              productBasket = basketLine
            } else {
              const product = await $http.get(productQuery)
              productBasket = {
                ...product,
                quantity: basketLine.quantity,
                prodType: basketLine.prodType,
                addedToCartDate: basketLine.addedToCartDate
              }
            }
            result.push(productBasket)
          } catch (err) {
            result.push({ ...basketLine, quantity: basketLine.quantity, hasError: true })
            console.log(err)
          }
          return result
        },
        []
      )
      return {
        id: basket[0].id,
        cart
      }
    })
  }

  /**
   *
   */
  static async saveBasket(payload) {
    if (payload.id) {
      return $http.put(`/baskets/${payload.id}`, payload)
    }

    return $http.post(`/baskets`, payload)
  }

  static async deleteBasket(payload) {
    return $http.delete(`/baskets/${payload.id}`)
  }

  /**
   * Check if a user has already done a quiz
   * @param {} payload
   * @returns choices if already done, empty object if empty
   */
  static async loadUserQuiz(payload) {
    const { userId, quizId } = payload
    return $http.get(`/userQuizs/users/${userId}/quizzes/${quizId}`)
  }

  /**
   * Send Quiz answers
   * @param {*} payload
   * @returns
   */
  static async sendQuizAnswers(payload) {
    return $http.post(`/userQuizs/answer`, payload)
  }

  /**
   * Load categories
   */
  static async loadCategories() {
    const url = Querier.query({ baseUrl: `/productCategories/` })
      .sort('name')
      .generate()
    return $http.get(url)
  }

  static async loadPricingList(productsList) {
    try {
      const productsPrices = await $http.post(`/pricinglists/productsprices`, {
        productsList: productsList
      })
      return productsPrices
    } catch (err) {
      console.log(err)
      return
    }
  }

  /**
   * Load already shopped items this week
   */

  static async getShoppedItems(shopId) {
    try {
      const shoppedItems = await $http.get(`/shops/${shopId}/shopped_since_monday`)
      return shoppedItems
    } catch (err) {
      console.error(err)
      return err
    }
  }
}
