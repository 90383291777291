import { NewsRepository } from './repository'
import { ProductsRepository } from '../products/repository'

export default {
  async LOAD_HEADLINE_ELEMENTS({ commit }) {
    const news = await NewsRepository.loadNewsHeadlines()
    const products = await ProductsRepository.loadProductsHeadlines()
    console.log(news, 'HEADLINE')
    commit('SET_HEADLINES', news.filter(a => a.type != 'public').concat(products))
  },

  /**
   *
   *
   */

  async LOAD_CATEGORIES({ commit }) {
    const categories = await NewsRepository.loadCategories()
    commit('SET_CATEGORIES', categories)
  },

  /**
   *
   *
   */

  async LOAD_MORE_ARTICLES({ commit, state }) {
    const articles = await NewsRepository.loadArticles(state.pagination)
    if (!articles.length) commit('SET_ALL_ARTICLES_COLLECT', true)

    commit(
      'SET_MORE_ARTICLES',
      articles.filter(a => a.type != 'public')
    )
  },
  /**
   *
   *
   */

  async LOAD_ARTICLES({ commit, state }) {
    const articles = await NewsRepository.loadArticles(state.pagination)
    commit(
      'SET_ARTICLES',
      articles.filter(a => a.type != 'public')
    )

    const total = await NewsRepository.getArticlesLength()
    const pages = Math.ceil(total / state.pagination.articlesPerPage)
    commit('SET_PAGES', pages)
  },

  /**
   *
   *
   */
  async LOAD_ARTICLE({ commit }, id) {
    const article = await NewsRepository.loadArticle(id)
    commit('SET_ARTICLE', article)
  },

  /**
   *
   *
   */
  async LOAD_LAST_ARTICLE({ commit }) {
    const article = await NewsRepository.loadLastArticle()
    if (article) commit('SET_ARTICLE', article.filter(a => a.type != 'public')[0])
  }
}
