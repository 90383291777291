export default {
  title: 'Filtres',
  submit: 'Valider',
  noFilter: 'Tout effacer',

  likes: {
    title: `Likes de l'utilisateur`,
    label: 'Produits likés'
  },

  audience: {
    title: 'Public',
    1: 'Enfants',
    2: 'Famille',
    3: 'Casual',
    4: 'Expert'
  },

  categories: {
    title: 'Catégories'
  },

  content: {
    title: 'Contenu additionnel',
    1: 'Visuels',
    2: 'Teaser',
    3: 'Règles',
    4: 'Concours',
    5: 'Quiz'
  },

  mechanisms: {
    title: 'Mécanismes'
  },

  editors: {
    title: 'Editeurs',
    other: 'Autre'
  },

  authors: {
    title: 'Auteurs'
  },

  illustrators: {
    title: 'Illustrateurs'
  },

  skills: {
    title: 'Compétences',
    1: 'Concentration et attention',
    2: 'Coopération',
    3: 'Créativité',
    4: ' Interaction sociale',
    5: ' Maths',
    6: 'Mémoire',
    7: 'Vitesse d’exécution',
    8: 'Perception visuelle',
    9: 'Résolution de problèmes',
    10: 'Motricité fine'
  }
}
