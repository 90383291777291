import { couponsRepository } from './repository'
import { dayjs } from '../../boot/other'

export default {
  CHECK_COUPON({ state, rootState, rootGetters }, layout) {
    const { coupon, context } = layout
    const products = rootGetters['products/cartGroupByProducts']
    if (state.couponsInCart.length > 0) {
      context.$q.notify({
        message: context.$t('cart.layout.discount.error.alreadyCoupon'),
        position: 'top',
        color: 'red'
      })
      return false
    } else if (!coupon.isPercentage && coupon.discount >= rootGetters['products/totalWithoutVAT']) {
      context.$q.notify({
        message: context.$t('cart.layout.discount.error.overReduc'),
        position: 'top',
        color: 'red'
      })
      return false
    }
    const product = Object.values(products).find(el => el.id == coupon?.productId)
    const ReducProduct = coupon => {
      if (product)
        if (coupon.type.indexOf('Colisage') != -1) return 0
        else if (coupon.isPercentage) return (product.price / 100) * coupon.discount
        else return coupon.discount
      return coupon.discount
    }
    if (coupon.expiredAt != null && dayjs().isAfter(coupon.expiredAt)) {
      context.$q.notify({
        message: context.$t('cart.layout.discount.error.exprired'),
        position: 'top',
        color: 'red'
      })
      return false
    } else if (dayjs().isBefore(coupon.usableAt)) {
      context.$q.notify({
        message: context.$t('cart.layout.discount.error.notyet'),
        position: 'top',
        color: 'red'
      })
      return false
    } else if (
      coupon.pricingListId != null &&
      coupon.pricingListId !== rootState.auth.user.mainShop.pricingListId
    ) {
      context.$q.notify({
        message: context.$t('cart.layout.discount.error.invalid'),
        position: 'top',
        color: 'red'
      })
      return false
    } else if (coupon.productId != null && !product) {
      context.$q.notify({
        message: context.$t('cart.layout.discount.error.badProduct'),
        position: 'top',
        color: 'red'
      })
      return false
    } else if (product && (product.pricePromo || product.id != coupon.productId)) {
      context.$q.notify({
        message: context.$t('cart.layout.discount.error.alreadyProduct'),
        position: 'top',
        color: 'red'
      })
      return false
    } else {
      return true
    }
  },
  async ADD_COUPON_IN_CART({ state, commit, dispatch }, layout) {
    const { couponInput, context } = layout

    const purchasedCoupons = state.purchasedCoupons

    const coupon = state.coupons.find(
      coupon =>
        coupon.code.toUpperCase() == couponInput.toUpperCase() &&
        !state.couponsInCart.find(c => coupon.id == c.id)
    )

    if (!coupon) {
      context.$q.notify({
        message: context.$t('cart.layout.discount.error.invalid'),
        position: 'top',
        color: 'red'
      })
      return false
    }

    if (
      coupon.isPurchasable &&
      !state.purchasedCoupons.some(pcoupon => pcoupon.couponId == coupon.id)
    ) {
      context.$q.notify({
        message: context.$t('cart.layout.discount.error.notBuyed'),
        position: 'top',
        color: 'red'
      })
      return false
    }

    const deleteCoupon = state.couponsInCart.find(
      c => coupon.productId && c.productId == coupon.productId
    )
    if (deleteCoupon) await dispatch('DELETE_COUPON_IN_CART', deleteCoupon)
    if (await dispatch('CHECK_COUPON', { coupon: coupon, context: context })) {
      commit('SET_COUPONS_IN_CART', [...state.couponsInCart, coupon])
      commit('products/SET_HAS_BASKET_CHANGED', true, { root: true })
      context.$q.notify({
        message: context.$t('cart.layout.discount.valid'),
        position: 'top',
        color: 'green'
      })
    }
  },
  async DELETE_COUPON_IN_CART({ commit, state, rootState, dispatch }, coupon) {
    const coupons = [...state.couponsInCart]
    coupons.splice(coupons.indexOf(coupon), 1)
    commit('SET_COUPONS_IN_CART', coupons)
    commit('products/SET_HAS_BASKET_CHANGED', true, { root: true })
    dispatch('products/SAVE_BASKET', null, { root: true })
  },

  async GET_COUPONS({ rootState, commit, dispatch }) {
    const coupons = await couponsRepository.getCoupons()
    await commit('SET_COUPONS', coupons)
    await dispatch('GET_PURCHASED_COUPONS')
  },
  async GET_PURCHASED_COUPONS({ rootState, commit }) {
    const coupons = await couponsRepository.getPurchasedCoupons({
      shopId: rootState.auth.user.shops[0].id
    })

    commit('SET_PURCHASED_COUPONS', coupons)
  },

  async BUY_COUPONS({ rootState, dispatch }, couponCode) {
    const coupon = await couponsRepository.buyCoupons({
      couponCode,
      shopId: rootState.auth.user.shops[0].id,
      quantity: 1
    })
    await dispatch('GET_PURCHASED_COUPONS')
    return coupon
  },
  PURGE_COUPONS_CART({getters, dispatch},) {
    const couponCart = getters['couponsInCart']
    couponCart.forEach(element => {
      if (!getters['checkIfCouponsExist'](element.id))
        dispatch('DELETE_COUPON_IN_CART', element)
    });
  }
}
