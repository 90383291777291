import { $http } from 'boot/axios'
import { Querier } from '@owliehq/querier'

export class ShopRepository {
  /**
   * get current shop
   */
  static async loadShop(shopId) {
    if (shopId === 'new')
      return {
        email: null,
        name: null,
        addressLine1: null,
        zipCode: null,
        city: null,
        phone: null,
        pictures: []
      }

    const url = Querier.query({ baseUrl: `/shops/${shopId}` })
      .populate('pictures')
      .generate()

    return await $http.get(url)
  }

  /**
   * get current shop user
   */
  static async loadShopUser(userId, shopId) {
    if (userId === 'new')
      return {
        email: null,
        firstName: null,
        lastName: null,
        bornAt: null,
        mobilePhone: null,
        pictures: [],
        shop: {
          position: null,
          canCommand: false,
          canBuyPoints: false,
          canEditShop: false,
          canUpdateSellers: false,
          canVisualizeCommands: false,
          canManageParcelReturns: false,
          canShareSocialMedias: false,
          shopId
        }
      }

    const url = Querier.query({ baseUrl: `/users/${userId}` })
      .populate('pictures')
      .populate('shops')
      .generate()

    return await $http.get(url)
  }

  /**
   * save shop
   */
  static async saveShop(shop) {
    const { id } = shop
    if (!id) {
      const streetName = shop.addressLine1.replace(/\d+/g, '').trim()
      shop.name = `${shop.name} - ${shop.city} - ${streetName}`
      shop.headOfficeVATNumber = 'FR' + shop.headOfficeVATNumber
      return $http.post('/shops/create', shop)
    }
    // quickfix
    shop.bornAt = shop.bornAt ? shop.bornAt  : null
    return $http.put(`/shops/${id}`, shop)
  }

  /**
   * save shop user
   */
  static async saveShopUser(user) {
    const { id } = user
    user.shop.id = user.shop.shopId
    if (!id) return $http.post('/users/create', user)
    // return $http.put(`/users/${id}/update`, user)
    return  $http.put(`/users/${id}`, user)
  }
}
