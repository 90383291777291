export default {
  buttonTurn: 'FAIRE TOURNER LA ROUE',
  buttonClose: 'FERMER',
  buttonBack: 'RETOUR',
  title1: 'GAGNEZ LE GROS LOT !',
  subTitle1: 'Faites tourner la roue pour tenter de gagner un maximum de cubes !',
  title2: 'GAGNÉ...',
  quit: 'QUITTER',
  allreadyPlayed1: "Vous avez déjà joué aujourd'hui",
  allreadyPlayed2: 'Retentez votre chance dès demain !',
  subTitle2a: {
    1: 'un cube !',
    2: 'deux cubes !',
    4: 'quatre cubes !',
    6: 'six cubes !',
    10: 'dix cubes !',
    15: 'quinze cubes !',
    20: 'vingt cubes !',
    50: 'cinquante cubes !!!'
  },
  subTitle2b: {
    1: 'Ne vous en faites pas, vous ferez mieux la prochaine fois !',
    2: 'Ne vous en faites pas, vous ferez mieux la prochaine fois !',
    4: "Ce n'est pas si mal, peut-être ferez-vous mieux la prochaine fois !",
    6: "Ce n'est pas si mal, peut-être ferez-vous mieux la prochaine fois !",
    10: "Joli score ! On dirait que c'est votre jour de chance ! ",
    15: "Joli score ! On dirait que c'est votre jour de chance ! ",
    20: "Joli score ! On dirait que c'est votre jour de chance ! ",
    50: "Score maximum, Bravo ! On peut dire que c'est votre jour de chance !"
  }
}
