export default {
  back: 'Retour',
  help: `Besoin d'aide ?`,
  tutos: 'Tutoriels',
  login: `Ma boutique a déjà un compte sur l'application`,

  shop: {
    title: 'Enregister ma boutique',
    subtitle: `Pour commencer veuillez renseigner les coordonnées de votre boutique. Pas de panique, vous pourrez les modifier par la suite.`,
    tutolink: `Pour plus d'informations sur comment renseigner le formulaire, <a target="_blank" href="https://tuto.tribuo.fr/tuto/inscription">cliquez ici</a>`,
    shop: 'Informations boutique',
    same: 'Ma société partage les mêmes informations que ma boutique',
    company: 'Informations société',

    form: {
      name: 'Nom de la boutique',
      address: 'Adresse',
      zipCode: 'Code postal',
      city: 'Commune',
      email: 'Email',
      phone: 'Téléphone',
      bornAt: 'Date anniversaire',
      headOfficeSocialReason: 'Raison sociale *',
      headOfficeLegalForm: 'Forme juridique *',
      headOfficeAddressLine1: 'Adresse du siège social *',
      headOfficeZipCode: 'Code postal *',
      headOfficeCity: 'Ville *',
      headOfficeCountry: 'Pays *',
      headOfficeSiret: 'Siret *',
      headOfficeEmail: 'Email *',
      headOfficePhone: 'Téléphone *',
      headOfficeVATNumber: 'Numéro TVA',
      noVat: 'TVA non applicable',
      error: 'Champ invalide',
      errorRequire: 'Tous les champs obligatoires ne sont pas renseignés'
    }
  },

  user: {
    title: 'Plus que quelques informations !',
    subtitle:
      'Finalisez la création de votre compte avec la création de votre profil ! Pas de panique, vous pourrez le modifier par la suite.',
    form: {
      lastName: 'Nom',
      firstName: 'Prénom',
      bornAt: 'Anniversaire',
      email: 'Email',
      mobilePhone: 'Téléphone',
      password: 'Mot de passe',
      passwordRepeat: 'Répétez le mot de passe',
      error: 'Champ invalide'
    }
  },
  onboarding: {
    title: 'Merci !',
    subtitle:
      'Votre demande a bien été prise en compte. Nos commerciaux reviendront vers vous dans les plus brefs délais. En attendant, tentez de résoudre ce taquin !'
  },
  newShopEmployee: {
    title: 'Oh, une nouvelle tête !',
    subtitle: `Nous sommes heureux de vous rencontrer ! Pour vous créer un compte, veuillez demander à votre gérant·e de vous créer un profil.`,
    instruction: {
      profile: 'Pour cela, il ou elle doit se rendre dans "mon profil"',
      shop: 'Sous la boutique à laquelle vous voulez être affilié...',
      addEmployee: '..pour créer un nouveau profil en cliquant sur "ajouter une personne"',
      voila: 'Suivez les instructions, et voilà !'
    }
  },
  success: 'Votre boutique a bien été créée, elle est en attente de validation par nos services.',
  failed: {
    E00010: 'Ce compte utilisateur est introuvable, merci de vérifier vos identifiants',
    E00011: 'Merci de bien vouloir vous re-authentifier',
    E00013: 'Merci de bien vouloir vous re-authentifier',
    E00025: 'Merci de bien vouloir vous re-authentifier',
    E00016: 'Merci de bien vouloir vous re-authentifier',
    E00017: `L'adresse e-mail de la boutique est déjà référencée chez Tribuo. Si vous avez perdu vos identifiants, vous pouvez les récupérer via le bouton dédié sur la page d\'authentification`,
    E00018: `L'adresse e-mail de votre compte est déjà référencée chez Tribuo. Si vous avez perdu vos identifiants, vous pouvez les récupérer via le bouton dédié sur la page d\'authentification`,
    E00019: 'Cette adresse e-mail est déjà utilisée'
  }
}
