import Vue from 'vue'
import VueRouter from 'vue-router'
import { decodeToken } from 'src/utils/tokens'
import queryString from 'query-string'

import routes from './routes'

Vue.use(VueRouter)

export default function({ store /* ssrContext */ }) {
  const Router = new VueRouter({
    scrollBehavior: () => ({ x: 0, y: 0 }),
    routes,
    mode: process.env.VUE_ROUTER_MODE,
    base: process.env.VUE_ROUTER_BASE
  })

  Router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    const accessToken = localStorage.getItem('accessToken')
    /*const tokenDate = localStorage.getItem('expiry')
    const expiry = new Date(tokenDate)
    const sixMonthsAgo = new Date().setMonth(new Date().getMonth() - 6)

    if (+sixMonthsAgo > expiry) localStorage.clear()*/
    //console.log('[Router - only in Dev]\nfrom:',from, 'to:', to)
    if (from.name != "ordering-orderForm" && to.name == "ordering-confirmation")
      return next('/')
    if (requiresAuth && !accessToken && to.name !== 'pendingAccount') {
      return next('/auth/login')
    }

    //If user is already logged, redirect to home page
    if (to.name === 'login' && accessToken) {
      return next('/')
    }

    if (accessToken) {
      const { id } = decodeToken(accessToken)
      store.commit('auth/SET_USER_ID', id)
    }

    return next()
  })

  return Router
}
