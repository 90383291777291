import { $http } from 'boot/axios'

export class UserRepository {
  /**
   * delete user
   */
  static deleteUser(payload) {
    return $http.post(`users/delete`, payload)
  }

  static getTitles() {
    return $http.get('users/titles')
  }

  static getSecondTitles() {
    return $http.get('users/second-titles')
  }

  static getMottos() {
    return $http.get('users/mottos')
  }

  static getPositions() {
    return $http.get('users/positions')
  }
}
