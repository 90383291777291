import { OrderingRepository } from './repository'
import { saveAs } from 'file-saver'
import availableOffers from '../../utils/offers'

export default {
  async GET_ORDERS({ commit, rootState }) {
    const orders = await OrderingRepository.getOrders(rootState.auth.user.mainShopId)
    if (orders) orders.sort((a, b) => b.updatedAt - a.updatedAt)
    commit('SET_ORDERS', orders)
  },

  async GET_ORDER({ commit, rootState }, orderId) {
    const order = await OrderingRepository.getOrder(orderId)
    commit('SET_ORDERDETAILSBYID', order)
    return order
  },

  async GET_SHIPPING_MODES({ commit }) {
    const shippingModes = await OrderingRepository.getShippingModes()
    commit('SET_SHIPPING_MODES', shippingModes)
  },

  async SEND_ORDER({ state, rootState, commit, getters, rootGetters }) {
    // Build the order object to send API
    try {
      const { shopId, shippingModeId, paymentModeId } = state.orderingInfos
      const userId = rootState.auth.userId
      const basketId = rootState.products.lastBasketId
      const cartDiscoverOffer = rootGetters['offers/getSelectedOffer']
      
      const couponCodes = rootGetters['coupons/couponsInCart'].map(coupon => coupon.code)
      const { shipmentInstructions, requestedFor } = rootGetters['ordering/orderingOptions']

      let order = {
        shopId,
        userId,
        shippingModeId,
        paymentModeId,
        basketId,
        couponCodes,
        shipmentInstructions,
        reference: 'orderFromApp'
      }
      if (requestedFor) order.requestedFor = requestedFor
      if (cartDiscoverOffer) order.discoverOffer = cartDiscoverOffer
      return await OrderingRepository.sendOrder(order)
    } catch (err) {
      throw err
    }
  },

  async GET_BILL_BY_ORDER_ID({}, payload) {
    const { id, reference } = payload
    try {
      const bill = await OrderingRepository.getBillByOrderId(id)
      const blob = new Blob([bill], { type: 'application/pdf' })
      saveAs(blob, 'invoice_' + reference + '.pdf')
    } catch (err) {
      throw err
    }
  }
}
