export default {
  title: 'Nous sommes heureux de vous revoir !',
  forgottenPassword: 'Mot de passe oublié ?',
  register: `C'est votre première connexion ?`,
  help: `Besoin d'aide ? `,

  form: {
    email: 'Adresse email',
    password: 'Mot de passe',
    remember: 'Se souvenir de moi',
    submit: 'Valider'
  },

  pendingAccount: {
    thankYou: 'Merci !',
    text:
      "Votre compte a bien été créé, il est en attente de validation de la part d'un commercial. Nous reviendrons vers vous dans les plus brefs délais !",
    disconnect: 'Me déconnecter'
  },

  error: {
    emptyField: 'Remplissez correctement tous les champs.',
    default: 'Une erreur est survenue. Merci de bien vouloir réessayer plus tard.',
    E00016: 'Une erreur est survenue. Merci de bien vouloir réessayer plus tard.',
    E00017: `Votre compte n'a pas encore été validé. Veuillez contacter le commercial de votre boutique.`,
    E00011:
      'Votre adresse e-mail ou votre mot de passe est incorrect, veuillez vérifier les informations saisies.',
    E00012:
      'Votre adresse e-mail ou votre mot de passe est incorrect, veuillez vérifier les informations saisies.',
    E00010:
      'Votre adresse e-mail ou votre mot de passe est incorrect, veuillez vérifier les informations saisies.',
    E00019: `Votre boutique n'est pas encore validée par nos services. Merci de bien vouloir réessayer plus tard.`
  }
}
