export default {
  addressCustomer: {
    name: '',
    addressLine1: '',
    zipCode: '',
    city: '',
    email: '',
    phone: ''
  },
  addressShop: null,
  chooseAddress: 'shop',
  entries: []
}
