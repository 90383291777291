import Vue from 'vue'

export default {
  RESET_COUPONS_IN_CART(state) {
    state.couponsInCart = []
  },
  PUSH_COUPON(state, coupon) {
    state.coupons.push(coupon)
  },
  PUSH_COUPON_IN_CART(state, coupon) {
    state.couponsInCart.push(coupon)
  }
}
