export default {
  menu: {
    news: 'Actualités',
    account: 'Mon profil',
    basket: 'Mon panier'
  },
  account: {
    settings: 'Paramètres',
    editProfile: 'Éditer mon profil',
    editShop: 'Éditer la boutique'
  },
  sav: 'Demande de SAV'
}
