export default {
  //format shop user
  SET_USER (state, user) {
    let {
      shops,
      shop,
      id,
      email,
      firstName,
      lastName,
      mobilePhone,
      pictures,
      bornAt,
      updatedAt
    } = user

    if (shops?.length) {
      const currentShop = shops.find((shop) => shop.id === state.shop.id)
      shop = currentShop.UserShop
    }
    const returnedUser = {
      shop,
      id,
      email,
      firstName,
      lastName,
      mobilePhone,
      pictures,
      bornAt,
      updatedAt
    }

    state.shopUser = returnedUser
  }
}
