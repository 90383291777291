import { $http } from 'boot/axios'
import { Querier } from '@owliehq/querier'

export class FilterRepository {
  static getAdditionalContents() {
    const url = Querier.query({ baseUrl: `/additionalContents` })
      .sort('name')
      .generate()
    return $http.get(url)
  }

  static getAudiences() {
    return $http.get('audiences')
  }

  static getAuthors() {
    const url = Querier.query({ baseUrl: `/creators` })
      .rawConditions({ type: 'author' })
      .sort('name')
      .generate()
    return $http.get(url)
  }

  static getEditors() {
    const url = Querier.query({ baseUrl: `/creators` })
      .rawConditions({ type: 'editor' })
      .sort('name')
      .generate()
    return $http.get(url)
  }

  static getIllustrators() {
    const url = Querier.query({ baseUrl: `/creators` })
      .rawConditions({ type: 'illustrator' })
      .sort('name')
      .generate()
    return $http.get(url)
  }

  static getSkills() {
    const url = Querier.query({ baseUrl: `/skills` })
      .sort('name')
      .generate()
    return $http.get(url)
  }

  static getMechanisms() {
    const url = Querier.query({ baseUrl: `/mechanisms` })
      .sort('name')
      .generate()
    return $http.get(url)
  }
}
