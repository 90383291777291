import { format } from 'date-fns'
import locale from 'date-fns/locale/fr'
import { i18n } from 'src/boot/i18n'

export default async ({ Vue }) => {
  Vue.filter('formatBirthday', date => {
    return date ? format(new Date(date), 'dd MMMM', { locale }) : ''
  })
  Vue.filter('formatFullDate', date => {
    return date ? format(new Date(date), 'dd MMMM yyyy', { locale }) : ''
  })

  Vue.filter('formatDate', date => {
    return date ? format(new Date(date), 'dd/MM/yyyy', { locale }) : ''
  })

  Vue.filter('formatDateHour', date => {
    return date ? format(new Date(date), 'dd/MM/yyyy - HH:mm', { locale }) : ''
  })

  Vue.filter('formatDateInput', date => {
    return date ? format(new Date(date), 'yyyy-MM-dd', { locale }) : ''
  })

  Vue.filter('formatPrice', price => {
    return (isNaN(price) ? 0 : price / 100).toFixed(2) + ' €'
  })

  Vue.filter('formatUserPosition', position => {
    switch (position) {
      case 'manager':
        return i18n.t('profile.roles.manager')
      case 'seller':
        return i18n.t('profile.roles.seller')
      default:
        return null
    }
  })

  Vue.filter('formatStock', stock => {
    switch (stock) {
      case 'active':
        return i18n.t('products.details.stock.active')
      case 'preOrder':
        return i18n.t('products.details.stock.preOrder')
      case 'unavailable':
        return i18n.t('products.details.stock.unavailable')
      default:
        return null
    }
  })

  Vue.filter('shortenStringForProductDesc', string => {
    if (string.length > 60) {
      return string.substring(0, 60) + '...'
    } else {
      return string
    }
  })
}
