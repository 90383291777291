import state from './state'
import mutations from './mutations'
import actions from './actions'

import { make } from 'vuex-pathify'

export default {
  namespaced: true,
  getters: {
    ...make.getters(state)
  },
  mutations: {
    ...make.mutations(state),
    ...mutations
  },
  state,
  actions
}
