function changeProductsPricesFromPricelist(products, pricingList) {
  for (let j = 0; j < products.products.length; j++) {
    if (typeof products.products[j].priceEuros !== 'undefined')
      products.products[j].price = products.products[j].priceEuros
  }
  if (pricingList && pricingList.length) {
    for (let i = 0; i < pricingList.length; i++) {
      const item = pricingList[i]
      const productIndex = products.products.findIndex(product => {
        return product.zohoId === item.productZohoId
      })
      if (typeof productIndex === 'number' && !products.products[productIndex].priceUpdated) {
        products.products[productIndex].price = item.price
        products.products[productIndex].oldPrice = item.oldPrice
        products.products[productIndex].priceUpdated = true
        if (item.price < item.oldPrice) products.products[productIndex].hasPromo = true
        else products.products[productIndex].hasPromo = false
        products.products[productIndex].hasPriceList = true
      }
    }
  }
  return products
}

function changeProductPriceFromPriceList(product, pricingList) {
  if (pricingList && pricingList.length) {
    const pricingListItemPosition = pricingList.findIndex(pricing => {
      return pricing.productZohoId === product.zohoId
    })
    if (typeof pricingListItemPosition === 'number') {
      product.price = pricingList[pricingListItemPosition].price
      product.oldPrice = pricingList[pricingListItemPosition].oldPrice
      if (
        pricingList[pricingListItemPosition].price < pricingList[pricingListItemPosition].oldPrice
      )
        product.hasPromo = true
      product.hasPriceList = true
    }
  }
  return product
}

export { changeProductPriceFromPriceList, changeProductsPricesFromPricelist }
