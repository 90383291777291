export default {
  article: {},
  articles: [],
  categories: [],
  headlines: [],
  AllArticlesCollect: false,
  display: {
    favorite: false
  },
  pagination: {
    articlesPerPage: 10,
    pageNumber: 1
  },
  pages: 0
}
