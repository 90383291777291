import { SettingsRepository } from './repository'

export default {
  /**
   *
   */
  async LOAD_CGV({ commit }) {
    const cgv = await SettingsRepository.getCGV()
    commit('SET_CGV', cgv[0])
  },

  /**
   *
   */
  async LOAD_ANNOUNCEMENT({ commit }) {
    const announcement = await SettingsRepository.getAnnouncement()
    commit('SET_ANNOUNCEMENT', announcement[0])
  },

  /**
   *
   */
  async LOAD_FAQ({ commit }) {
    const faq = await SettingsRepository.getFAQ()
    commit('SET_FAQ', faq)
  },

  /**
   *
   */
  async LOAD_FAQ_CATEGORIES({ commit }) {
    const categories = await SettingsRepository.getFAQCategories()
    commit('SET_FAQ_CATEGORIES', categories)
  }
}
