import Vue from 'vue'
import Vuex from 'vuex'
import pathify from './pathify'
import createPersistedState from 'vuex-persistedstate'

import auth from './auth'
import coupons from './coupons'
import offers from './offers'
import products from './products'
import ordering from './ordering'
import shops from './shops'
import users from './users'
import files from './files'
import filters from './filters'
import gamification from './gamification'
import news from './news'
import tutorial from './tutorial'
import settings from './settings'
import sav from './sav'

Vue.use(Vuex)
/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default function(/* { ssrContext } */) {
  const Store = new Vuex.Store({
    plugins: [
      pathify.plugin,
      createPersistedState({
        paths: ['auth', 'products.cart', 'ordering.orderingInfos']
      })
    ],
    modules: {
      auth,
      coupons,
      offers,
      products,
      ordering,
      shops,
      users,
      files,
      filters,
      gamification,
      settings,
      sav,
      news,
      tutorial
    },

    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: process.env.DEV
  })

  return Store
}
