import { $http } from 'boot/axios'
import { Querier } from '@owliehq/querier'

export class NewsRepository {
  /**
   *
   *
   */
  static loadNewsHeadlines() {
    const url = Querier.query({ baseUrl: `/blogPosts` })
      .sortDesc('publishedAt')
      .rawConditions({ headline: true })
      .populate('banner')
      .generate()

    return $http.get(url)
  }

  /**
   *
   *
   */

  static loadCategories() {
    return $http.get('blogCategories')
  }

  /**
   *
   *
   */
  static loadArticles(pagination) {
    const { pageNumber, articlesPerPage } = pagination

    const url = Querier.query({ resultsPerPage: articlesPerPage, baseUrl: `/blogPosts` })
      .sortDesc('publishedAt')
      .rawConditions({
        publishedAt: {
          $lt: new Date()
        }
      })
      .populate('categories banner author')
      .page(pageNumber)
      .generate()

    return $http.get(url)
  }
  /**
   *
   *
   */
  static getArticlesLength() {
    const url = Querier.query({ baseUrl: `/blogPosts/count` })
      .sortDesc('publishedAt')
      .rawConditions({
        publishedAt: {
          $lt: new Date()
        }
      })
      .generate()

    return $http.get(url)
  }

  /**
   *
   *
   */
  static loadArticle(id) {
    const url = Querier.query({ baseUrl: `/blogPosts/${id}` })
      .populate('categories banner author')
      .generate()
    return $http.get(url)
  }

  /**
   *
   *
   */

  static loadLastArticle() {
    const url = Querier.query({ baseUrl: `/blogPosts` })
      .sortDesc('publishedAt')
      .populate('categories banner author')
      .limit(10)
      .generate()
    return $http.get(url)
  }
}
