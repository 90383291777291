export default {
  getShopById: state => id => state.user.shops.find(shop => shop.id === id),

  otherShops: state => {
    const { user } = state
    const { mainShop, shops } = user
    return shops.reduce((acc, shop) => {
      if (shop.id !== mainShop.id) acc.push(shop)
      return acc
    }, [])
  },

  roleByShopId: state => id => {
    const shop = state.user.shops.find(shop => shop.id === id)
    return shop.UserShop.position
  },

  shopsAsOptions: state => {
    const { user } = state
    const { shops } = user

    return shops.reduce((result, shop) => {
      const { id, name, addressLine1, zipCode, city } = shop

      const shopAddress = `${addressLine1} ${zipCode} ${city}`
      const position = shop.UserShop.position

      result.push({ value: id, label: name + ' - ' + shopAddress, position })

      return result
    }, [])
  },

  shopsUser: state => {
    return state.user.shops
  },

  shopsAsAddressOptions: state => {
    const { user } = state
    const { shops, mainShop } = user
    return shops.reduce((res, shop) => {
      const { id, name, addressLine1, zipCode, city, phone } = shop
      res.push({
        value: id,
        label: name + '\n' + addressLine1 + '\n' + zipCode + ' ' + city + '\n \n' + phone
      })
      return res
    }, [])
  },

  shopsAsNameOptions: state => {
    const { user } = state
    const { shops, mainShop } = user
    return shops.reduce((res, shop) => {
      const { id, name } = shop
      res.push({
        value: id,
        label: name
      })
      return res
    }, [])
  },

  getPaymentById: state => id => state.paymentModes.find(payment => payment.id === id),

  cardPayments: state => state.paymentModes.filter(payment => payment.type === 'card'),

  LCRPayments: state => state.paymentModes.filter(payment => payment.type === 'LCR'),

  bankTransferPayments: state => state.paymentModes.filter(payment => payment.type === 'Virement'),

  bankDirectDebitPayments: state =>
    state.paymentModes.filter(payment => payment.type === 'bankDirectDebit'),

  getLoveByProductId: state => productId =>
    state.productLoves.find(love => love.productId === productId),

  getLoveByGoodyId: state => goodyId => state.goodyLoves.find(love => love.goodyId === goodyId),

  getLoveByPressArticleId: state => pressArticleId =>
    state.pressArticleLoves.find(love => love.blogPostId === pressArticleId)
}
