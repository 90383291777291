export default {
  add: `Ajouter une boutique`,
  addUser: 'Ajouter une personne',
  siretNotFound: `Votre n'êtes pas connu de nos services, Veuillez renseigner le formulaire`,
  edit: {
    name: 'Nom',
    addressLine1: 'Adresse',
    zipCode: 'Code postal',
    city: 'Commune',
    email: 'Email',
    phone: 'Téléphone',
    bornAt: 'Ouverture',

    businessName: 'Raison sociale',
    legalForm: 'Forme juridique',
    headquartersAddress: 'Adresse du siège social',
    siret: 'Numéro siret',
    vatNumber: 'Numéro de TVA',

    cancel: 'Annuler',
    submit: 'Enregistrer',
    success: 'Les données de la boutique ont bien été mises à jour.'
  },

  user: {
    updatedAt: `Dernière modification le {date}`,
    delete: {
      title: 'Supprimer un vendeur',
      question:
        'Vous vous apprêtez à supprimer un vendeur, ce processus est irréversible, êtes-vous sûr.e.s ?',
      cancel: 'Annuler',
      confirm: 'Supprimer'
    },
    edit: {
      lastName: 'Nom de famille',
      firstName: 'Prénom',
      bornAt: 'Anniversaire',
      email: 'Email',
      mobilePhone: 'Téléphone',
      title: 'Titre',
      secondTitle: 'Titre 2',
      motto: 'Devise',
      position: 'Poste',
      shop: 'Boutique',
      cancel: 'Annuler',

      authorizations: {
        title: 'Gérer les autorisations',
        canCommand: 'Passer une commande',
        canBuyPoints: 'Acheter avec des cubes',
        canEditShop: 'Éditer la boutique',
        canUpdateSellers: 'Gérer les accès des vendeurs',
        canVisualizeCommands: 'Consulter les commandes (bons, suivi, factures)',
        canManageParcelReturns: 'Gérer les SAV (demander un SAV, suivre les SAV)',
        canShareSocialMedias: 'Publier sur les réseaux sociaux'
      },

      addSubmit: 'Ajouter un vendeur',
      editSubmit: 'Enregistrer',
      delete: 'Retirer ce vendeur',
      success: 'Les données utilisateur ont bien été mises à jour.'
    },
    positions: {
      manager: 'Gérant',
      seller: 'Vendeur'
    }
  }
}
