import { FilterRepository } from './repository'

export default {
  /**
   * Get additional contents
   */
  async GET_ADDITIONAL_CONTENTS({ commit }) {
    const contents = await FilterRepository.getAdditionalContents()
    commit('SET_ADDITIONAL_CONTENTS', contents)
  },

  /**
   * Get audiences
   */
  async GET_AUDIENCES({ commit }) {
    const audiences = await FilterRepository.getAudiences()
    commit('SET_AUDIENCES', audiences)
  },

  /**
   * Get authors, editors, illustrators
   */
  async GET_AUTHORS({ commit }) {
    const authors = await FilterRepository.getAuthors()
    commit('SET_AUTHORS', authors)
  },

  async GET_EDITORS({ commit }) {
    const editors = await FilterRepository.getEditors()
    commit('SET_EDITORS', editors)
  },

  async GET_ILLUSTRATORS({ commit }) {
    const illustrators = await FilterRepository.getIllustrators()
    commit('SET_ILLUSTRATORS', illustrators)
  },

  /**
   * Get skills
   */
  async GET_SKILLS({ commit }) {
    const skills = await FilterRepository.getSkills()
    commit('SET_SKILLS', skills)
  },

  /**
   * Get mechanisms
   */
  async GET_MECHANISMS({ commit }) {
    const mechanisms = await FilterRepository.getMechanisms()
    commit('SET_MECHANISMS', mechanisms)
  },

  RESET({ commit }) {
    commit('RESET')
  },
  CLEAR_FILTERS({ commit }) {
    commit('CLEAR_FILTERS')
  }
}
