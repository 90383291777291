import { decodeToken } from 'src/utils/tokens'
import { $http, $httpWithoutRefresh } from 'boot/axios'
import { dayjs } from '../../boot/other'

import { Querier } from '@owliehq/querier'

export class AuthRepository {
  /**
   * Login user
   * @param {*} email
   * @param {*} password
   * @param {*} remember
   */

  static async login(email, password, remember) {
    try {
      let expiry
      //Get user access token
      const { accessToken, refreshToken, isValidated } = await $httpWithoutRefresh.post(
        '/auth/login',
        {
          email,
          password
        }
      )
      localStorage.setItem('accessToken', accessToken)
      localStorage.setItem('refreshToken', refreshToken)
      if (remember) {
        expiry = dayjs().add(30, 'day')
      } else {
        expiry = dayjs().add(1, 'day')
      }
      const lastRefresh = dayjs()
      localStorage.setItem('lastRefresh', lastRefresh)
      localStorage.setItem('expiry', expiry)
      const { id } = decodeToken(accessToken)
      const userProfile = await this.getProfile(id)
      let tokens = { accessToken, refreshToken, expiry, lastRefresh }
      return { ...userProfile, ...{ isValidated }, ...tokens }
    } catch (err) {
      console.log(err)
      if (err?.response?.data?.errorCode) {
        if (err.response.data.errorCode !== 'E00019') {
          await this.logout()
        }
      }
      if (err?.response?.data) {
        throw err.response.data
      } else {
        throw err
      }
    }
  }

  /**
   *  Register
   */

  static async register(user) {
    try {
      // Without refresh because no refresh token required for login
      const { accessToken, refreshToken } = await $httpWithoutRefresh.post(
        '/auth/signup-with-shop',
        user
      )
    } catch (err) {
      await AuthRepository.logout()
      throw err
    }
  }

  /**
   * Logout user
   */

  static logout() {
    const token = localStorage.getItem('refreshToken')

    if (!token) return

    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('expiry')

    return $httpWithoutRefresh.post('/auth/logout', { token })
  }

  /**
   * Reset password email
   */
  static sendResetPasswordEmail(payload) {
    return $http.post('/auth/forgot-password', payload)
  }

  /**
   * Reset password asked
   */
  static async resetPassword(payload) {
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get('token')

    if (token) {
      const { accessToken } = await $http
        .post(`/auth/refresh`, { token })
        .then(response => response)

      payload.token = accessToken
    }

    return $http.post('/auth/reset-password', payload)
  }

  /**
   * change password asked
   */
  static changePassword(payload) {
    return $http.post('/auth/reset-password-connected', payload)
  }

  /**
   * Get profile
   * @param {*} userId
   */
  static async getProfile(userId) {
    const url = Querier.query({ baseUrl: `/users/${userId}` })
      .populate('pictures')
      .populate('mainShop')
      .populate('shops.pictures shops.users.pictures')
      .generate()

    try {
      const getProfile = await $http.get(url)
      return getProfile
    } catch (err) {
      console.error('AUTH ERROR', err)
      return err
    }
  }

  /**
   * Retrieve user profile
   */
  static saveProfile(user) {
    const { id } = user
    return $http.put(`/users/${id}`, user)
  }

  /**
   * get user salesman
   */

  static async getSalesmanInfos(salesmanId) {
    const url = Querier.query({ baseUrl: `/users/${salesmanId}` })
      .populate('pictures')
      .generate()
    const salesmanInfos = await $http.get(url)
    return salesmanInfos
  }

  /**
   * get payment modes by shop
   */
  static async getPaymentModes(shopId) {
    const url = Querier.query({ baseUrl: `/paymentmodes` })
      .rawConditions({ shopId })
      .generate()
    const payments = await $http.get(url)
    return payments
  }

  static async savePaymentMode(payload) {
    const { id } = payload
    if (!id) return $http.post('/paymentmodes', payload)
    return $http.put(`/paymentmodes/${id}`, payload)
  }

  static async deletePaymentMode(payload) {
    const { id } = payload

    return $http.delete(`/paymentmodes/${id}`)
  }

  /**
   *
   * create product love
   */
  static async createProductLove(love) {
    return $http.post(`/productloves`, love)
  }

  /**
   *
   * create product love
   */
  static async createGoodyLove(love) {
    return $http.post(`/goodyloves`, love)
  }

  /**
   *
   * delete love
   */
  static async deleteProductLove(loveId) {
    return $http.delete(`/productloves/${loveId}`)
  }

  /**
   *
   * delete love
   */
  static async deleteGoodyLove(loveId) {
    return $http.delete(`/goodyloves/${loveId}`)
  }

  /**
   * get loves by user id
   */
  static async getProductLoves(payload) {
    const { type, userId } = payload
    const url = Querier.query({ baseUrl: `/${type}loves` })
      .rawConditions({ userId })
      .generate()
    const loves = await $http.get(url)
    return loves
  }

  /**
   *
   * create product love
   */
  static async createPostLove(love) {
    return $http.post(`/blogpostloves`, love)
  }

  /**
   *
   * delete love
   */
  static async deletePostLove(loveId) {
    return $http.delete(`/blogpostloves/${loveId}`)
  }

  /**
   * get loves by user id
   */
  static async getPostLoves(userId) {
    const url = Querier.query({ baseUrl: `/blogpostloves` })
      .rawConditions({ userId })
      .generate()
    const loves = await $http.get(url)
    return loves
  }
  /**
   *
   */
  static async getGdpr() {
    return $http.post('/users/gdpr')
  }
}
