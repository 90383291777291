import { $http } from 'boot/axios'

export class couponsRepository {
  static async getCoupons() {
    return $http.get(`/coupons`)
  }

  static async getPurchasedCoupons(payload) {
    const coupons = await $http.get(`/couponPurchaseds?shopid=${payload.shopId}`)
    return coupons
  }

  static async buyCoupons(payload) {
    return $http.post(`/coupons/purchase`, payload)
  }
}
