import { make } from 'vuex-pathify'
import state from './state.js'
import actions from './actions.js'

export default {
  namespaced: true,
  getters: {
    ...make.getters(state)
  },
  mutations: {
    ...make.mutations(state)
  },
  state,
  actions
}
