import { Querier } from '@owliehq/querier'
import { $http } from 'src/boot/axios'

export class SettingsRepository {
  /**
   *
   */

  static getCGV() {
    const url = Querier.query({ baseUrl: '/conditions' })
      .sortDesc('createdAt')
      .limit(1)
      .generate()

    return $http.get(url)
  }

  /**
   *
   */
  static getAnnouncement() {
    const url = Querier.query({ baseUrl: '/announcements' })
      .sortDesc('createdAt')
      .limit(1)
      .generate()

    return $http.get(url)
  }

  /**
   *
   */

  static getFAQ() {
    const url = Querier.query({ baseUrl: '/faqArticles' }).generate()

    return $http.get(url)
  }

  /**
   *
   */

  static getFAQCategories() {
    const url = Querier.query({ baseUrl: '/faqCategories' }).generate()

    return $http.get(url)
  }
}
