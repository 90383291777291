export default {
  orders: null,
  selectedOrder: null,

  orderingInfos: {
    shopId: null,
    shippingModeId: null,
    paymentModeId: null
  },

  shippingModes: [],
  orderingOptions: {
    shipmentInstructions: '',
    requestedFor: null
  }
}
