export default {
  title: 'Mot de passe oublié ?',
  login: 'Retour',
  subtitle:
    'Saisissez votre e-mail pour recevoir un lien qui vous permettra de modifier votre mot de passe.',

  resetTitle: 'Réinitialisation du mot de passe',
  resetSubtitle: 'Saisissez votre nouveau mot de passe.',

  emailSent: `Un email de réinitialisation vous a été envoyé. Veuillez cliquer sur le lien présent dans le mail pour mettre à jour votre mot de passe.`,

  form: {
    email: 'Adresse email',
    password: 'Mot de passe',
    confirmPassword: 'Confirmation mot de passe',
    success: `Votre mot de passe a bien été mis à jour.`,
    error: {
      email: `Email invalide.`,
      password: `Mot de passe invalide.`,
      confirmPassword: `Veuillez saisir le même mot de passe dans les deux champs.`
    }
  }
}
