export default {
  myCart: 'Mon panier',
  createCart: 'Créer un panier type',
  transform: 'Transformer en panier type',
  standardCarts: 'Voir mes paniers type',
  payment: 'Valider mon panier',
  invalidCsv: 'Bon de commande invalide',
  buttonImport: 'Importer un bon de commande',
  cancelImport: 'Annuler',
  importNotice:
    "<b>Attention:</b> Importer un bon de commande remplacera votre panier actuel.<br> Vous pouvez télécharger l'intégralité de nos produits disponibles sur la page boutique.",
  emptyCard: 'Votre panier est vide.',
  cancelItem: {
    title: 'Retrait du panier',
    body: 'Etes-vous sûr de vouloir retirer cet article de votre panier ?'
  },
  deleteditem: `Un produit présent dans votre panier n'est malheureusement plus disponible. Il a été retiré de votre panier.`,
  onlyCubesItems:
    'Votre commande ne peut pas comporter uniquement des produits ayant une valeur en cubes.',
  discoverOffer: 'Offre découverte',
  discoverOffers: 'Offres découverte',
  discoverOfferDesc: "Bénéficiez d'un produit offert parmi la sélection suivante :",
  addThisDiscoveryOffer: 'Choisir ce produit',
  discoveryOfferModal: {
    title: "Pensez à l'offre découverte !",
    desc:
      "Avant de valider votre panier vous pouvez sélectionner gratuitement l'offre découverte de votre choix parmi une sélection de jeux disponibles.",
    closeBtn: "J'en profite"
  },
  discount: {
    title: 'Appliquer une réduction',
    button: 'Voir mes coupons',
    error: {
      invalid: 'Coupon invalide',
      notFound: 'Coupon inexistant',
      exprired: 'Désolé coupon expiré',
      badProduct: `Le produit associé à ce coupon n'est pas dans votre panier`,
      overReduc: `Votre panier ne comporte pas assez de produits pour profiter de cette offre`,
      yetCoupon: `Coupon déjà utilisé`,
      notyet: `Ce coupon n'est pas disponible`,
      alreadyProduct: `Une réduction est déjà appliquée à ce produit`,
      fullGoodies: `Votre panier ne peut contenir uniquement des goodies`,
      notEnoughCubes: `Vous n'avez pas assez de cubes`,
      alreadyCoupon: `Un coupon est déjà présent sur votre panier`,
      notBuyed:
        'Ce coupon est bien existant cependant il n\'est pas en votre possession. Rendez-vous dans la section "Cubes" pour l\'acheter.'
    },
    valid: 'Coupon accepté'
  },
  sendCart: 'Valider mon panier',
  outOfStock: 'Rupture',
  outOfStockCartMessage:
    'Attention ! Certains de vos articles sont en rupture de stock. Veuillez retirer ces articles avant de passer commande.',
  deleteDeletedItemsFromCart: 'Supprimer les articles en rupture de mon panier',
  buttonDownloadSampleCartCSV: 'Télécharger un exemple'
}
