import { GamificationRepository } from './repository'

export default {
  async GET_POINT_ENTRIES({ rootState, commit }) {
    const { id } = rootState.auth.user.shops[0]
    const pointEntries = await GamificationRepository.getPointEntries({ shopId: id })

    commit('SET_POINT_ENTRIES', pointEntries)
  },
  //
  async POST_WHEEL({}, shopId) {
    return await GamificationRepository.postWheel(shopId)
  },
  async CHECK_WHEEL({}, shopId) {
    return await GamificationRepository.checkWheel(shopId)
  }
}
