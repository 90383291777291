import Vue from 'vue'
import dayjs from 'dayjs'
require('dayjs/locale/fr')
import VueAddtohomescreen from '@owliehq/vue-addtohomescreen'

Vue.use(VueAddtohomescreen)

var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
dayjs.locale('fr')

Object.defineProperties(Vue.prototype, {
  $date: {
    get () {
      return dayjs
    }
  }
})

export { dayjs } 