import { $http } from 'boot/axios'
import { Querier } from '@owliehq/querier'
export class OrderingRepository {
  static async sendOrder(payload) {
    try {
      const order = {...payload}
      await $http.post('/orders/create', order)
    } catch (err) {
      console.error(err, 'ERROR')
      throw err
    }
  }

  static async getOrders(shopId) {
    const orders = await $http.get(`/shops/${shopId}/orders`)
    return orders
  }

  static async getOrder(id) {
    const url = Querier.query({ baseUrl: `/orders/${id}` })
      .populate('goodyOrders')
      .populate('productOrders')
      .populate('orderDiscounts')
      .populate('shippingMode')
      .generate()
    return await $http.get(url)
  }

  static async getShippingModes() {
    const shipping = await $http.get('/shippingmodes')
    return shipping
  }

  static async getBillByOrderId(orderId) {
    try {
      const bill = await $http.get(`/orders/${orderId}/download`, { responseType: 'arraybuffer' })
      return bill
    } catch (err) {
      throw err
    }
  }
}
