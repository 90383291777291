import Vue from 'vue'
import { offerManager } from '../../utils/offers'
import clone from 'clone'
export default {
  // Add a product in the cart products list
  SET_MORE_PRODUCTS(state, payload) {
    payload.forEach(product => {
      state.products.products.push(product)
    })
  },

  SET_MORE_GOODIES(state, payload) {
    payload.forEach(goodie => {
      state.goodies.products.push(goodie)
    })
  },

  // Add a product in the sav products listing
  SET_MORE_SAV_PRODUCTS(state, payload) {
    payload.forEach(product => {
      state.productsSav.push(product)
    })
  },

  // Add a product in the cart products list
  ADD_PRODUCT_TO_CART(state, payload) {
    const prod = state.cart.find(p => p?.id == payload?.id)
    if (payload.prodType === 'goody') {
      const paidType = payload.paidInCubes ? 'cubes' : 'euros'
      if (prod && prod.quantity[paidType]) {
        prod.quantity[paidType]++
      } else {
        if (paidType === 'cubes')
          state.cart.push({ ...payload, ...{ quantity: { cubes: 1, euros: 0 } } })
        else state.cart.push({ ...payload, ...{ quantity: { cubes: 0, euros: 1 } } })
      }
    } else {
      if (payload.quantity) {
        if (prod) {
          prod.quantity = payload.quantity
        } else {
          state.cart.push(payload)
        }
        return
      }
      if (prod) prod.quantity[payload.quantityType]++
      else state.cart.push({ ...payload, quantity: { [payload.quantityType]: 1 } })
    }
  },

  // Remove a product from cart
  REMOVE_PRODUCT_FROM_CART(state, payload) {
    const index = state.cart.findIndex(prod => prod?.id === payload?.id)
    if (payload.prodType === 'goody') {
      const paidType = payload.paidInCubes ? 'cubes' : 'euros'
      if (state.cart[index] && state.cart[index].quantity[paidType]) {
        if (state.cart[index].quantity[paidType] == 1) state.cart[index].quantity[paidType] = 0
        else state.cart[index].quantity[paidType]--
      }
    } else {
      const prod = state.cart.find(p => p?.id == payload?.id)
      if (payload.quantity) {
        if (prod) {
          prod.quantity = payload.quantity
        } else {
          state.cart.push(payload)
        }
        return
      }
      if (prod && prod.quantity[payload.quantityType] > 0) prod.quantity[payload.quantityType]--
    }
  },

  REMOVE_PRODUCT_COMPLETELY_FROM_CART(state, payload) {
    const index = state.cart.findIndex(prod => prod?.id === payload?.id)
    state.cart.splice(index, 1)
    Vue.set(state, 'deletedFromCart', [])
  },

  CHANGE_QUANTITY_PRODUCT_FROM_CART(state, payload) {
    const prod = state.cart.find(p => p.id == payload.id)
    if (payload.prodType === 'goody') {
      if (prod && payload.quantity <= 0 && prod.quantity.cubes <= 0 && prod.quantity.euros <= 0) {
        state.cart.splice(
          state.cart.findIndex(prod => prod.id === payload.product.id),
          1
        )
      } else if (prod) {
        prod.quantity = payload.quantity
      } else {
        state.cart.push({ ...payload, quantity: payload.quantity })
      }
    } else {
      if (prod) {
        prod.quantity = { ...prod.quantity, ...payload.quantity }
      } else {
        state.cart.push(payload)
      }
    }
  },

  UPDATE_PRODUCT_OFFERS(state, payload) {
    const { returnQuantities, returnAvailableOffers } = offerManager(
      clone(payload.quantity),
      clone(payload.availableOffersArray),
      clone(payload.weekBuyLimit)
    )
    const prod = state.cart.find(p => p.id == payload.id)
    prod.quantity = returnQuantities
    prod.availableOffers = returnAvailableOffers
  },

  PUSH_PRICINGLIST(state, pricingList) {
    if (pricingList && pricingList.length) {
      pricingList.forEach(product => {
        Vue.set(state.pricingList, product.productZohoId, product.price)
      })
    }
  }
}
