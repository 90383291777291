export default {
  cancel: `Fermer tout le tutoriel`,
  back: `Attends...quoi ?`,
  next: `Compris !`,
  home: [
    {
      title: `Bienvenue chez Tribuo !`,
      text: `Vous êtes ici sur la page d'accueil.
            Vous y retrouverez les dernières nouveautés,
            des raccourcis vers la boutique, des bons plans,
            les dernières news et même des mini jeux pour passer le temps !`,
      target: `#logo`,
      target_mobile: `#navbar-footer`
    },
    {
      title: `Le S.A.V. vous ?`,
      text: `Si vous avez un problème avec l'un de nos jeux,
      nous vous renvoyons les pièces gratuitement chez vous ou directement chez le client.`,
      target: '#sav-btn'
    }
  ],
  basket: [
    {
      title: `Mon panier`,
      text: `Comme Gilbert ! C’est là que vous retrouvez tous vos articles. Vous pouvez ajuster vos quantités,
     transformer votre panier en panier type, ou encore ajouter un panier type à votre panier actuel !`,
      target: `#basket`
    },
    {
      title: `Appliquer une réduction`,
      text: `Placez vos cubes dans la remise ! Utilisez vos cubes instantanément pour obtenir des réductions et
       appliquez-les à votre panier pour transformer vos cubes… En réduction sur la facture !`,
      target: `#discountBasket`
    }
  ],
  library: [
    {
      title: `C'est l'histoire d'une boutique qui rentre dans une boutique`,
      text: `Vous êtes ici dans la boutique. Vous pouvez consulter les informations d’un jeu,
    ou directement ajouter du contenu à votre panier si vous êtes sûrs de vous ! Vous pouvez aussi ajouter un jeu dans vos coups de cœurs en cliquant sur le cœur.`,
      target: `#game`,
      moveTo: `goodies`
    },
    {
      title: `Faites briller vos jeux !`,
      text: `Plus que de simples goodies et PLV, vous trouverez ici tout plein d’outils pour faire vivre votre boutique et proposer du contenu à vos clients !`,
      target: `#goodie`,
      moveTo: `points`
    },
    {
      title: `Kézakube ?`,
      text: `Cumulez un maximum de cubes pour obtenir des articles exclusifs et des réductions à la clef ! Vous trouverez ici tout ce que vous pouvez obtenir en dépensant vos cubes.`,
      target: `#point`,
      moveTo: `likes`
    },
    {
      title: `Favori`,
      text: `Retrouvez ici tous les jeux que vous préférez pour les racheter au plus vite`,
      target: `#likes`,
      moveTo: `goodPlans`
    },
    {
      title: `Bon plan`,
      text: `Nouveautés, promotions, jeux offerts... Les pépites de votre boutique préférée sont réunies dans cet onglet`,
      target: `#plan`
    }
  ],
  details: {
    infos: {
      title: `Ah ! La fameuse fiche produit…`,
      text: `Au-delà de son aspect pratique et technique,
        vous pouvez y découvrir d’autres outils. Cliquez sur les onglets pour essayer !`,
      target: `#infos`
    },
    totems: {
      title: `Les totems`,
      text: `Les quoi ? Les totems rassemblent des récompenses, des prix virtuels et des citations de la presse !
        Le meilleur moyen de ne louper aucune des dernières nouveautés qui vont cartonner !`,
      target: `#totems`
    },
    tools: {
      title: `Les outils`,
      text: `Votre nouvelle caisse à outils. Que vous ayez besoin de visuels pour faire la promotion d’un jeu,
        habiller votre vitrine, consulter un teaser, revoir un point de règles ou publier sur vos réseaux…
        Tout est là !`,
      target: `#tools`
    },
    press: {
      title: `La presse`,
      text: `Vite vite vite ça presse ! Les articles, blogs, test et avis du jeu sont tous rassemblés ici !
      Ne loupez pas les dernières infos, et soyez au courant avant vos clients de ce qu’il se dit dans le monde ludique !`,
      target: `#press`
    },
    quizz: {
      title: `Quiz`,
      text: `C'est à votre tour de jouer ! Testez vos connaissances sur le jeu et faites le plein de cubes !`,
      target: `#quizz`
    }
  },
  news: {
    title: `Breaking news !`,
    text: `Suivez les actualités de votre distributeur et de vos éditeurs,
       pour ne rien manquer des nouveautés !`,
    target: `#news`
  },
  profile: {
    main: {
      title: `Hey, mais c’est moi !`,
      text: `Modifiez votre profil, accédez aux paramètres, et retrouvez toutes les informations utiles et
       pratiques de votre boutique, vos commandes, vos cubes et votre commercial.`,
      target: `#owner`
    },
    ordering: {
      title: `Où en sont les affaires ?`,
      text: `Retrouvez ici toutes vos commandes en cours ou passées ainsi que vos factures.`,
      target: `#ordering`
    },
    points: {
      title: `Keskecé ?`,
      text: `Les cubes, qu’est-ce que c’est, comment on en gagne, à quoi ça sert ?
       Retrouvez ici toutes les infos à propos de ces ressources mystérieuses.`,
      target: `#points`
    },
    salesman: {
      title: `Mon Commercial`,
      text: `Bravo, vous venez de débloquer un nouveau personnage !
      Retrouvez les coordonnées de votre commercial, contactez-le par mail, téléphone ou tchat (sms) ou programmez votre prochain rendez-vous !`,
      target: `#salesman`
    }
  },
  error: `[Invalid Parameter] Please pass the vue context`
}
