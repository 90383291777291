import global from './global'
import auth from './auth'
import products from './products'
import cart from './cart'
import ordering from './ordering'
import shops from './shops.js'
import profile from './profile.js'
import settings from './settings.js'
import filters from './filters.js'
import sav from './sav.js'
import wheel from './wheel'
import coupons from './coupons.js'
import tooltips from './tooltips.js'
import tutorial from './tutorial.js'

export default {
  wheel,
  global,
  auth,
  products,
  cart,
  ordering,
  shops,
  profile,
  settings,
  filters,
  sav,
  tooltips,
  tutorial,
  coupons,
  submit: 'Valider',
  success: 'Les données ont bien été enregistrées.',
  failed: `Une erreur est survenue, veuillez réessayer dans quelques instants. Si le problème persiste, veuillez-nous contacter à aide@tribuo.fr`,
  news: {
    read: "Lire l'article",
    noData: 'Bientôt de nouvelles nouvelles !',
    endOfResult: 'Fin des articles'
  }
}
