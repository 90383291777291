import { TutorialRepository } from './repository'

export default {
  ABORT() {
    TutorialRepository.tutorialAbort()
    this.set(`tutorial/disableTutorial`, true)
  },

  SCRIPTCOMPLETE({}, script) {
    const scriptsComplete = {
      ...this.get(`tutorial/scriptsComplete`),
      ...{ [script]: true }
    }
    TutorialRepository.setScriptTutorialDone(scriptsComplete)
    this.set(`tutorial/scriptsComplete`, scriptsComplete)
  },

  MOVETOSTORE() {
    this.set(`tutorial/scriptsComplete`, TutorialRepository.getScriptsDone() || {})
    this.set(`tutorial/disableTutorial`, TutorialRepository.getDisableTutorial() || false)
  },

  RESET() {
    TutorialRepository.tutorialReset()
    this.set(`tutorial/scriptsComplete`, {})
    this.set(`tutorial/disableTutorial`, false)
  }
}
