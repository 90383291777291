export default {
  title: 'Demande de SAV',
  product: 'Produit n° {number}',
  success: 'Votre demande de SAV a bien été envoyée.',
  form: {
    search: 'Produit concerné...',
    addressShop: 'Selectionner une boutique...',
    emptySearch: 'Pas de résultats',
    product: 'Produit concerné',
    parts: 'Pièce(s) concernée(s)',
    reason: 'Motif du SAV',
    details: 'Remarque(s) et précision(s)',
    name: 'Nom',
    address: 'Adresse',
    zipCode: 'Code postal',
    city: 'Commune',
    email: 'Email',
    phone: 'Téléphone',
    actions: {
      add: 'Ajouter un autre produit',
      save: 'Valider la demande'
    }
  },
  reasons: {
    missing: 'Pièce(s) manquante(s)',
    defective: 'Pièce(s) défectueuse(s)',
    lost: 'Pièce(s) perdue(s)',
    broken: `Pièce(s) cassée(s) ou abîmée(s) à l'usure`
  }
}
