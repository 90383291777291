export default {
  title: 'Paramètres',
  darkMode: 'Mode sombre',

  notifications: {
    games: 'Mes jeux favoris',
    releases: 'Les nouvelles sorties',
    news: 'Les news',
    orders: 'Suivi de commandes',
    quiz: 'Nouveau quiz'
  },

  help: {
    title: 'Help',
    text: `Un problème ? Un Bug ? Consultez notre FAQ ou contactez notre support client !`,
    faq: 'Consulter la FAQ',
    tutos: 'Accèder aux tutoriels',
    cookies: 'Accèder à la gestion des cookies'
  },
  changePassword: {
    title: 'Changer mon mot de passe',
    form: {
      password: 'Mot de passe actuel',
      newPassword: 'Nouveau mot de passe',
      confirmPassword: 'Confirmation mot de passe',
      success: `Votre mot de passe a bien été mis à jour.`,
      wrongPassword: `Le mot de passe renseigné n'est pas votre mot de passe actuel.`,
      error: {
        password: `Mot de passe invalide.`,
        confirmPassword: `Veuillez saisir le même mot de passe dans les deux champs.`
      }
    }
  },
  tutorial: {
    title: 'Revoir les tutoriels',
    success: 'Reprenons depuis le début'
  },

  personalData: {
    title: 'Télécharger mes données personnelles',
    form: {
      checkbox: 'Je confirme demander un export de mes données personnelles'
    },
    actions: {
      submit: `Demander l'export`
    }
  },

  about: {
    title: 'À propos',
    text: `Application développée par Owlie, avec le soutien de la région Grand-Est`
  },

  legals: {
    title: 'Mentions légales',
    cgv: 'Conditions Générales de Vente',
    downloadData: 'Télécharger mes données personnelles'
  },

  cgv: {
    lastUpdate: `Dernière modification le {date}`,
    hint1: {
      1: 'Les',
      2: ' CGV',
      3: `, vous allez voir c'est `,
      4: 'INOUI'
    },
    hint2: {
      1: 'Alors, ',
      2: 'we go '
    },
    accept: `J'accepte les Conditions Générales de Vente`
  },

  faq: {
    title: `Page d'aide`
  },

  close: 'Fermer',
  logout: 'Déconnexion'
}
