import { make } from 'vuex-pathify'
import state from './state.js'
import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'

export default {
  namespaced: true,
  getters: {
    ...make.getters(state),
    ...getters
  },
  mutations: {
    ...make.mutations(state),
    ...mutations
  },
  state,
  actions
}
