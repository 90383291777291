export const getDefaultState = () => {
  return {
    additionalContents: null,
    authors: null,
    editors: null,
    illustrators: null,
    skills: null,
    mechanisms: null,

    frontFilters: {
      likes: false,
      goodPlans: false,
      nouveau: false,
      precommande: false
    },

    filters: {
      search: null,
      skillIds: [],
      illustratorIds: [],
      authorIds: [],
      editorIds: [],
      mechanismIds: [],
      additionalContentIds: [],
      categoryIds: []
    },

    // Only used for research in SAV Form
    filtersSav: {
      search: null
    },
    CLEAR_FILTERS: false
  }
}
export default getDefaultState()
