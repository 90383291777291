export default () => {
  tarteaucitron.init({
    privacyUrl: 'https://tribuo.fr/mentions-legales',
    bodyPosition: 'bottom',
    hashtag: '#tarteaucitron',
    cookieName: 'tarteaucitron',
    orientation: 'middle',
    groupServices: false,
    serviceDefaultState: 'wait',
    showAlertSmall: false,
    cookieslist: false,
    closePopup: true,
    showIcon: false,
    // "iconSrc": "", /* Optionnal: URL or base64 encoded image */
    iconPosition: 'BottomLeft',
    adblocker: false,
    DenyAllCta: true,
    AcceptAllCta: true,
    highPrivacy: true,
    handleBrowserDNTRequest: false,
    removeCredit: true,
    moreInfoLink: true,
    useExternalCss: false,
    useExternalJs: false,
    // cookieDomain: 'app.tribuo.fr',
    readmoreLink: 'https://tribuo.fr/mentions-legales',
    mandatory: false,
    mandatoryCta: true
  })
}
