import { savRepository } from './repository'

export default {
  /**
   * Save SAV request
   *
   */
  async SAVE_SAV_REQUEST({ state }, entries) {
    const address = state.chooseAddress == 'shop' ? state.addressShop : state.addressCustomer
    return savRepository.saveSAVRequest(entries, address)
  }
}
