import clone from 'clone'

const offersList = {
  demo_50: {
    pool: 'demo',
    poolPriority: 1,
    type: 'minProducts',
    nextProductDiscountPercent: 50,
    minProducts: 6,
    available: false,
    availableQuantity: 1
  },
  demo_30: {
    pool: 'demo',
    poolPriority: 2,
    type: 'minProducts',
    nextProductDiscountPercent: 30,
    minProducts: 3,
    available: false,
    availableQuantity: 1
  },
  multiple_6: {
    type: 'multipleProduct',
    nextProductDiscountPercent: 100,
    multipleProducts: 6,
    available: false,
    availableQuantity: 0
  },
  multiple_12: {
    type: 'multipleProduct',
    nextProductDiscountPercent: 100,
    multipleProducts: 12,
    available: false,
    availableQuantity: 0
  }
}

export default offersList

const offerManager = (quantities, productAvailableOffers, weekBuyLimit = 0) => {
  let availableOffers = clone(offersList)
  if (quantities.classic && productAvailableOffers && productAvailableOffers.length) {
    productAvailableOffers.forEach(offerName => {
      if (
        availableOffers[offerName].type === 'minProducts' &&
        quantities.classic >= availableOffers[offerName].minProducts
      ) {
        availableOffers[offerName].available = true
        if (typeof quantities[offerName] !== 'number') quantities[offerName] = 0
        if (availableOffers[offerName].pool) {
          const offerInPool = Object.entries(availableOffers).filter(
            offer => offer[1].pool === availableOffers[offerName].pool
          )
          const offerInPoolActive = offerInPool.filter(offer => offer[1].available === true)
          if (offerInPoolActive.length > 1) {
            const offerTopPriority = offerInPool.sort((a, b) => {
              return a - b
            })[0]
            offerInPoolActive.forEach(offerInPoolDetail => {
              if (offerTopPriority[0] !== offerInPoolDetail[0]) {
                availableOffers[offerInPoolDetail[0]].available = false
                delete quantities[offerInPoolDetail[0]]
              }
            })
          }
        }
      } else if (
        availableOffers[offerName].type === 'multipleProduct' &&
        quantities.classic >= availableOffers[offerName].multipleProducts
      ) {
        if (quantities.classic / availableOffers[offerName].multipleProducts > 0) {
          let offerQuantity = Math.floor(
            quantities.classic / availableOffers[offerName].multipleProducts
          )
          availableOffers[offerName].availableQuantity = offerQuantity
          quantities[offerName] = offerQuantity
          const totalQuantities = Object.values(quantities)
            .filter(quantity => quantity >= 0)
            .reduce((a, b) => a + b)
          if (
            weekBuyLimit &&
            weekBuyLimit > 0 &&
            quantities.classic % 12 === 0 &&
            totalQuantities > weekBuyLimit &&
            offerQuantity > 0
          ) {
            delete quantities[offerName]
          }
          if (quantities[offerName] > 0) availableOffers[offerName].available = true

        } else {
          availableOffers[offerName].available = false
          availableOffers[offerName].availableQuantity = 0
          delete quantities[offerName]
        }
      } else {
        delete quantities[offerName]
        availableOffers[offerName].available = false
      }
    })
  }
  return { returnQuantities: quantities, returnAvailableOffers: availableOffers }
}

export { offerManager }
