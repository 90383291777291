export default {
  number: 'Commande n°',
  status: 'Statut',
  draft: 'En cours de validation',
  confirmed: 'Confirmée',
  reliquate: 'Reliquat',
  shipped: 'Envoyée',
  fulfilled: 'Fermée',
  void: 'Annulée',
  errorNoTrack: "Votre commande n'est pas suivie, voyez avec votre commercial",
  actions: {
    tracking: 'Voir le suivi',
    consult: 'Consulter',
    download: 'Télécharger'
  }
}
