export default {
  games: 'Jeux',
  carts: 'Paniers types',
  goodies: 'Goodies/PLV',
  new: 'Nouveautés',
  points: 'Cubes',
  likes: 'Mes coups de',
  goodPlans: 'Bons plans',
  preorder: 'Précommandes'
}
