import Vue from 'vue'
import VueShepherd from 'vue-shepherd'
import { existOnI18n } from './i18n'
Vue.use(VueShepherd)

export const scripts = {
  HOME: 'home',
  DETAILS: {
    INFOS: 'details.infos',
    TOTEMS: 'details.totems',
    TOOLS: 'details.tools',
    PRESS: 'details.press',
    QUIZZ: 'details.quizz'
  },
  LIBRARY: 'library',
  BASKET: 'basket',
  NEWS: 'news',
  PROFILE: {
    MAIN: 'profile.main',
    ORDERING: 'profile.ordering',
    POINTS: 'profile.points',
    SALESMAN: 'profile.salesman'
  }
}

export class Tutorial {
  constructor(context) {
    if (context) this.init(context)
  }
  /**
   *
   */
  init(context) {
    if (typeof context !== 'object')
      throw new Error(`[Invalid Parameter] Please pass the vue context`)
    this.context = context
    this.buttons = {
      cancel: {
        action() {
          context.$store.dispatch('tutorial/ABORT')
          return this.cancel()
        },
        text: context.$t('tutorial.cancel')
      },
      back: {
        action() {
          return this.back()
        },
        text: context.$t('tutorial.back')
      },
      next: {
        action() {
          return this.next()
        },
        text: context.$t('tutorial.next')
      },
      close: {
        text: 'X',
        classes: 'shepherd-button-close',
        action: function() {
          return this.hide()
        }
      },
      nextMoveTo: path => {
        return {
          action() {
            context.$router.push({ path }).catch()
            return this.next()
          },
          text: context.$t('tutorial.next')
        }
      }
    }
  }
  /**
   *
   */
  getTarget(script) {
    if (!existOnI18n(`tutorial.${script}.target`)) return this.context.$el
    else if (this.context.$q.screen.width < 1025 && existOnI18n(`tutorial.${script}.target_mobile`))
      return this.context.$t(`tutorial.${script}.target_mobile`)
    else return this.context.$t(`tutorial.${script}.target`)
  }
  /**
   *
   */
  addStep(script, buttons) {
    this.tour.addStep({
      title: `<span class="text-h1">${this.context.$t(`tutorial.${script}.title`)}</span>`,
      text: `<p class="text-body2">${this.context.$t(`tutorial.${script}.text`)}</p>`,
      attachTo: { element: this.getTarget(script), on: 'top-start' },
      buttons
    })
  }
  /**
   *
   */
  addSteps(script) {
    this.context.$t(`tutorial.${script}`).forEach((el, index) => {
      const next = el.moveTo
      this.addStep(script + `[${index}]`, [
        index !== 0 ? this.buttons.back : this.buttons.cancel,
        !next ? this.buttons.next : this.buttons.nextMoveTo(next),
        this.buttons.close
      ])
    })
  }
  /**
   *
   */
  launchTutorialAbout(script) {
    if (!this.context)
      throw new Error(`[Bad usage] Please init class before use with Tutorial.init(context)`)
    const storeTuto = this.context.$store.get('tutorial')
    this.tour = this.context.$shepherd({
      defaultStepOptions: {
        classes: 'shepherd-theme-custom',
        scrollTo: {
          behavior: 'smooth',
          block: 'center'
        }
      },
      useModalOverlay: true
    })
    if (storeTuto.disableTutorial || storeTuto.scriptsComplete[script] != undefined) return

    if (!this.context.$t(`tutorial.${script}`).length)
      this.addStep(script, [this.buttons.cancel, this.buttons.next])
    else this.addSteps(script)

    this.context.$store.dispatch('tutorial/SCRIPTCOMPLETE', script)
    setTimeout(() => this.tour.start(), 1000)
  }
}

export default ({ Vue }) => {
  Vue.prototype.$tutorial = new Tutorial()
}
