export default {
  free: 'Offerts !',
  addToCart: 'Ajouter à mon panier',
  seeCart: 'Voir mon panier',
  keepPurchasing: 'Continuer mes achats',
  productsToCartValidation: 'Vos articles ont bien été ajoutés au panier',
  suggestions: 'Vous aimerez peut-être :',
  total: 'Total',
  unityHt: 'Unité HT',
  totalAfterDiscount: 'Total après réductions',
  taxesFreeTotal: 'Total HT',
  taxesFree: 'HT',
  taxesFreeProduct: 'HT produits',
  taxesIncluded: 'TTC',
  vat: 'TVA',
  couponReduction: 'Réduction coupons',
  totalPoints: 'Total de cubes',
  shippingFees: 'Frais de port HT',
  freeShipping: 'Plus que {price} pour bénéficier des frais de port offerts !',
  freeShippingActive: 'Les frais de port pour la livraison standard sont offerts !',
  discountOnExpressShipping: 'Les frais de port express sont à moitié prix !',
  freeGame: 'Jeu démo gratuit',
  freeDiscover: 'OFFERT',
  orderError: {
    discountTooHigh: 'Les coupons utilisés excédent le maximum autorisé sur cette commande.',
    invoiceNotGenerated:
      'Un problème est survenu. Merci de bien vouloir réessayer dans quelques instants.',
    notEnoughPoints:
      'Votre solde de points ne vous permet pas de passer cette commande; merci de vérifier votre panier.',
    orderStatusNotDraft:
      'Un problème est survenu. Merci de bien vouloir réessayer dans quelques instants.',
    minimumCommandNotReached: `Le montant minimum de commande n'a pas été atteint. Merci de compléter votre panier avant de passer commande.`,
    creditLimitExceeded:
      'Une erreur est survenu avec votre commande, veuillez contacter votre commercial pour continuer.'
  },
  offer: {
    discover: 'Offre découverte',
    demo_50: 'Jeu démo -50%',
    demo_30: 'Jeu démo -30%',
    multiple_12: 'Jeu offert',
    multiple_6: 'Jeu offert'
  },
  orderHasReliquate:
    "Votre commande comporte des produits en cours de réassort. Acceptez-vous qu'un reliquat comprenant ces produits soit généré et expédié ultérieurement ?",
  orderHasReliquateAnswer:
    "Oui, j'accepte que les produits en cours de réassort fassent partie d'une commande reliquat.",
  orderHasReliquateNotice:
    'Dans le cas ou vous ne souhaitez pas de reliquat, merci de supprimer les produits concernés de votre panier.',
  reliquate: 'Reliquat(s)',
  updatedQuantities:
    'Certaines quantité(s) ont été mises à jour car certains stocks ne sont plus disponibles: {items}',
  updatedQuantitiesOnItem: 'Quantités mises à jour'
}
