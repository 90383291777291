import { make } from 'vuex-pathify'
import state from './state.js'
import actions from './actions.js'
import getters from './getters'
import mutations from './mutations'

export default {
  namespaced: true,
  getters: {
    ...getters,
    ...make.getters(state)
  },
  mutations: {
    ...mutations,
    ...make.mutations(state)
  },
  state,
  actions
}
