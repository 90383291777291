import { FilesRepository } from './repository'

export default {
  async DELETE_SHOP_FILES({}, files) {
    const promises = files.map((file) => FilesRepository.deleteShopFile(file))
    return Promise.all(promises)
  },

  /**
   *
   */
  async DELETE_USER_FILES({}, files) {
    const promises = files.map((file) => FilesRepository.deleteUserFile(file))
    return Promise.all(promises)
  },

  /**
   *
   */
  async UPLOAD_SHOP_FILE({}, payload) {
    return FilesRepository.createShopFile(payload)
  },

  /**
   *
   */
  async UPLOAD_USER_FILE({}, payload) {
    return FilesRepository.createUserFile(payload)
  }
}
