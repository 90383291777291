export default {
  return: 'Retour',
  close: 'Fermer',
  stocks: 'Stock',
  pieces: 'unités',
  preOrder: 'En pré-commande',
  outOfStock: 'Rupture de stock',
  lowStock: 'Peu de stock',
  hasStock: 'En stock',
  reStock: 'En réapprovisionnement',
  unity: 'Unité',
  price: 'Prix HT',
  msrp: 'Prix brut TTC',

  noPressArticle: `Aucun article n'a été rédigé sur ce produit pour le moment.`,
  noInfo: `Aucun information n'est disponible sur ce produit pour le moment.`,
  noDetail: `Aucune description pour ce produit n'est disponible pour le moment.`,
  noDefailFun: `Y'a t'il besoin d'en ajouter ?`,
  unityPrice: `Prix à l'unité`,
  or: 'ou',
  validate: 'Valider',
  stock: {
    active: 'En stock',
    preOrder: 'En précommande',
    //TODO : back guys confirmation
    unavailable: 'Indisponible'
  },

  tabs: {
    infos: 'Infos',
    totems: 'Totems',
    tools: 'Outils',
    press: 'Presse',
    quiz: 'Quiz',
    advice: 'Le conseil de '
  },

  infos: {
    releaseAt: 'Sortie le',
    category: 'Catégorie'
  },
  gamePack: {
    size: 'Format',
    weight: 'Poids par jeu'
  },
  carton: {
    dimensions: 'Dimensions',
    weight: 'Poids',
    gameNbr: ' Jeux par carton'
  },

  quiz: {
    points: {
      many: 'Ce quiz rapporte %{points} cubes par bonne réponse',
      one: 'Ce quiz rapporte 1 point par bonne réponse',
      none: 'Ce quiz ne rapporte pas de cubes'
    },
    res: 'Vous avez obtenu {points} cubes',
    actions: {
      validate: 'Valider mes réponses'
    }
  },

  tools: {
    noData: 'Les visuels, vidéos et règles de ce jeu ne sont pas encore disponibles.',
    visuals: 'Les visuels',
    videos: 'Les vidéos',
    downloadRules: 'Télécharger les règles',
    soon: 'A venir'
  },

  coupons: {
    defaultDesc: 'Achat en cubes'
  },
  qtemax: 'Qte.<br>max',
  qtemaxTooltip: 'Quantité maximum hebdomadaire atteinte.',
  addToCart: 'Ajouter à mon panier',
  addToCartPreorder: 'Pré-commander',
  addToCartXs: 'Ajouter au panier',
  addToCartSuccess: 'Le produit a été ajouté à votre panier.',
  addToCartReliquate:
    "Le produit a été ajouté à votre panier cependant la quantité demandée dépasse le stock actuel. Les produits manquants feront partie d'une commande reliquat.",
  addToCartUnavailableStock:
    'La quantitée demandée excède le stock disponible. La quantité maximale de ce produit a été ajoutée à votre panier.'
}
