import { $http } from 'boot/axios'

export class GamificationRepository {
  static async getPointEntries (payload) {
    const { shopId } = payload
    return $http.get(`pointEntries/shops/${shopId}`)
  }
  static async postWheel (id) {
    return await $http.post('wheelshops/turn', { shopId: id })
  }
  //check last turn  :
  static async checkWheel (shopId) {
    let wheelShop
    try {
      wheelShop = await $http.get(`wheelshops/last/${shopId}`)
    } catch {
      wheelShop = null
    }
    return wheelShop
  }
}
