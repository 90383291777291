import state from './state'
import mutations from './mutations'
import getters from './getters'

import { make } from 'vuex-pathify'
import actions from './actions'

export default {
  namespaced: true,
  getters: {
    ...make.getters(state),
    ...getters
  },
  mutations: {
    ...make.mutations(state),
    ...mutations
  },
  actions,
  state
}
