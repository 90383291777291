import { InvoiceCalculator } from '@owliehq/money'

export default {
  getShippingById: state => id => state.shippingModes.find(shipping => shipping.id === id),

  standardShipping: state =>
    state.shippingModes.filter(ship => ship.zohoId === process.env.SHIPPING_IDS.STANDARD),

  expressShipping: state =>
    state.shippingModes.filter(ship => ship.zohoId === process.env.SHIPPING_IDS.EXPRESS),

  ordersSort: state => {
    let orders = []
    if (state.orders?.length) {
      orders = state.orders.map(order => {
        let products = []
        for (let product in order.products) {
          const finalProduct = {
            ...order.products[product],
            ...{ quantity: { classic: parseInt(order.products[product].ProductOrder.quantity) } }
          }
          products.push(finalProduct)
        }
        order.products = products
        return order
      })
    }
    orders.sort((a, b) => {
      return a.reference < b.reference ? 1 : -1
    })
    return orders
  },

  // Get products from a specific order formatted
  getFormattedProductsByOrderId: state => id => {
    const order = state.orders.find(order => order.id === id)
    const allProducts = { ...order.products, ...order.goodies }
    const productsReturned = Object.values(allProducts)?.map(product => {
      const formatted = { ...product, quantity: product['ProductOrder'].quantity }
      return formatted
    })
    return productsReturned
  },

  getFormattedGoodiesByOrderId: state => id => {
    return (
      state.orders
        .find(order => order.id === id)
        .goodies?.map(goody => {
          const formatted = { ...goody, quantity: goody['GoodyOrder'].quantity }
          return formatted
        }) || []
    )
  },

  getGoodiesByOrderId: (state, getters) => id =>
    getters.getFormattedGoodiesByOrderId(id).reduce((acc, goody) => {
      return (acc += goody.price * goody.quantity.cubes)
    }, 0),

  // Get total without VAT from a specific order
  totalWithoutVATByOrderId: (state, getters) => id =>
    getters.getFormattedProductsByOrderId(id).reduce((acc, prod) => {
      return (acc += prod.price * prod.quantity)
    }, 0),

  // Get total of VAT at 5.5% from a specific order
  totalVAT55ByOrderId: (state, getters) => id => {
    return new InvoiceCalculator(
      getters.getFormattedProductsByOrderId(id).reduce((acc, prod) => {
        if (prod.vat === 550) {
          acc.push({
            price: prod.price,
            vat: prod.vat / 10000,
            quantity: prod.quantity
          })
        }
        return acc
      }, [])
    ).totalVAT
  },

  // Get total of VAT at 20% from a specific order
  totalVAT200ByOrderId: (state, getters) => id => {
    return new InvoiceCalculator(
      getters.getFormattedProductsByOrderId(id).reduce((acc, prod) => {
        if (prod.vat === 2000) {
          acc.push({
            price: prod.price,
            vat: prod.vat / 10000,
            quantity: prod.quantity
          })
        }
        return acc
      }, [])
    ).totalVAT
  },

  // Get total of VAT from a specific order
  totalVATByOrderId: (state, getters) => id => {
    return getters.totalVAT55ByOrderId(id) + getters.totalVAT200ByOrderId(id)
  },

  // Get total WITH VAT from a specific order
  totalByOrderId: (state, getters) => id => {
    return getters.totalWithoutVATByOrderId(id) + getters.totalVATByOrderId(id)
  },

  // Get all types of totals from a specific order
  getTotalsByOrderId: (state, getters) => id => {
    return {
      total: getters.totalByOrderId(id),
      totalVAT: getters.totalVATByOrderId(id),
      totalVAT55: getters.totalVAT55ByOrderId(id),
      totalVAT200: getters.totalVAT200ByOrderId(id),
      totalWithoutVAT: getters.totalWithoutVATByOrderId(id),
      goodies: getters.getGoodiesByOrderId(id) || 0,
      discount: state.orders.find(order => order.id === id).discount || 0
    }
  }
}
