import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from 'src/i18n'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'fr',
  fallbackLocale: 'fr',
  messages
})

export default ({ app }) => {
  // Set i18n instance on app
  app.i18n = i18n
}

const existOnI18n = element => {
  return element.split('.').reduce((recipient, donor) => {
    const index = parseInt(donor.substring(donor.lastIndexOf('[') + 1, donor.lastIndexOf(']')))
    if (isNaN(index)) return recipient[donor]
    return recipient[donor.substring(0, donor.lastIndexOf('['))][index]
  }, messages.fr) !== undefined
    ? true
    : false
}

export { i18n, existOnI18n }
