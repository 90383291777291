const requiresAuth = true

const routes = [
  {
    path: '/wheel',
    name: 'wheel',
    component: () => import('pages/gamification/WheelGame.vue')
  },
  //
  {
    path: '/auth',
    component: () => import('layouts/LightLayout.vue'),
    redirect: 'auth/login',
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('pages/auth/Login.vue')
      },
      {
        path: 'register',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        redirect: 'register/shop',
        children: [
          {
            path: 'shop',
            name: 'registerShop',
            component: () => import('pages/auth/register/Shop.vue')
          },
          {
            path: 'user',
            name: 'registerUser',
            component: () => import('pages/auth/register/User.vue')
          },
          {
            path: 'newEmployee',
            name: 'registerNewEmployee',
            component: () => import('src/pages/auth/register/NewShopEmployee.vue')
          }
        ]
      },
      {
        path: 'forgotten-password',
        name: 'forgottenPassword',
        component: () => import('pages/auth/ForgottenPassword.vue')
      },
      {
        path: 'reset-password',
        name: 'resetPasswordForm',
        component: () => import('pages/auth/ResetPasswordForm.vue')
      },
      {
        path: 'pending-account',
        name: 'pendingAccount',
        component: () => import('pages/auth/PendingAccount.vue')
      }
    ]
  },

  {
    path: '/edit-profile',
    component: () => import('layouts/LightLayout.vue'),
    children: [
      {
        path: '',
        name: 'editProfile',
        component: () => import('pages/profile/EditProfileForm.vue'),
        meta: { requiresAuth }
      }
    ]
  },

  {
    path: '/settings',
    component: () => import('layouts/LightLayout.vue'),
    children: [
      {
        path: '',
        name: 'settings',
        component: () => import('pages/Settings.vue'),
        meta: { requiresAuth }
      },
      {
        path: 'cgv',
        name: 'cgv',
        component: () => import('pages/settings/CGV.vue'),
        meta: { requiresAuth }
      },
      {
        path: 'faq',
        name: 'faq',
        component: () => import('pages/settings/FAQ.vue'),
        meta: { requiresAuth }
      }
    ]
  },

  {
    path: '/logout',
    name: 'logout',
    component: () => import('pages/Logout.vue'),
    meta: { requiresAuth }
  },

  {
    path: '/onboarding',
    component: () => import('layouts/LightLayout.vue'),
    children: [
      {
        path: '',
        name: 'onboarding',
        component: () => import('pages/auth/ResetPasswordForm.vue')
      }
    ]
  },

  {
    path: '/profile',
    component: () => import('layouts/ProfileLayout.vue'),
    redirect: 'profile/shops',
    children: [
      {
        path: 'shops',
        name: 'userShops',
        component: () => import('pages/profile/UserShops.vue'),
        meta: { requiresAuth }
      },
      {
        path: 'shops/edit/:id',
        name: 'editUserShop',
        component: () => import('pages/shops/EditShopForm.vue'),
        meta: { requiresAuth }
      },
      {
        path: 'shops/user/:id',
        name: 'shopUserDetails',
        component: () => import('pages/shops/ShopUserDetails.vue'),
        meta: { requiresAuth }
      },
      {
        path: 'shops/user/edit/:id',
        name: 'editShopUser',
        component: () => import('pages/shops/EditShopUserForm.vue'),
        meta: { requiresAuth }
      },
      {
        path: 'orders',
        name: 'userOrders',
        redirect: '/profile/orders/list',
        component: () => import('pages/profile/UserOrders.vue'),
        meta: { requiresAuth },
        children: [
          {
            path: 'list',
            name: 'userOrdersList',
            component: () => import('components/ordering/Listing')
          }
        ]
      },
      // {
      //   path: 'paymentModes',
      //   name: 'userPaymentModes',
      //   component: () => import('pages/profile/UserPaymentModes.vue'),
      //   meta: { requiresAuth }
      // },
      {
        path: 'gamification',
        name: 'userGamification',
        component: () => import('pages/profile/UserGamification.vue'),
        meta: { requiresAuth }
      },
      {
        path: 'salesman',
        name: 'userSalesman',
        component: () => import('pages/profile/UserSalesman.vue'),
        meta: { requiresAuth }
      }
    ]
  },

  {
    path: '/help',
    component: () => import('layouts/MainLayout.vue'),
    children: [{ path: '', name: 'help', component: () => import('pages/Home.vue') }]
  },

  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'home',
        redirect: '/products',
        component: () => import('pages/Home.vue'),
        meta: { requiresAuth }
      },
      // products
      {
        path: 'products',
        redirect: 'products/home',
        meta: { requiresAuth },
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: 'home',
            name: 'productsHome',
            component: () => import('pages/products/ProductsHome.vue')
          },
          {
            path: 'listing/:type',
            name: 'productsListing',
            component: () => import('pages/products/ProductsListing.vue')
          },

          {
            path: 'details/:name/:id',
            name: 'productDetails',
            component: () => import('pages/products/ProductDetails.vue')
          }
        ]
      },
      // cart
      {
        path: 'cart',
        redirect: 'cart/current',
        meta: { requiresAuth },
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: 'current',
            name: 'currentCart',
            component: () => import('pages/cart/Cart.vue')
          },
          {
            path: 'coupons',
            name: 'CouponsCart',
            component: () => import('pages/cart/AddCouponToCart.vue')
          },
          {
            path: 'preview/:id',
            name: 'productPreview',
            component: () => import('pages/cart/AddProductToCart.vue')
          },
          {
            path: 'goodiepreview/:id',
            name: 'goodiePreview',
            component: () => import('pages/cart/AddProductToCart.vue')
          },
          {
            path: 'validation',
            name: 'productsAddedToCart',
            component: () => import('pages/cart/AddProductToCartValidation.vue')
          },

          {
            path: 'create',
            name: 'create-cart',
            component: () => import('pages/cart/CreateCart.vue')
          }
        ]
      },
      { path: 'profile', component: () => import('pages/Home.vue') },
      {
        path: 'news',
        name: 'news',
        component: () => import('pages/news/ArticlesListing.vue'),
        meta: { requiresAuth }
      },
      {
        path: 'news/details/:id',
        name: 'articleDetails',
        component: () => import('pages/news/ArticleDetails.vue'),
        meta: { requiresAuth }
      }
    ]
  },

  {
    path: '/filters',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'filters',
        component: () => import('pages/Filters.vue')
      }
    ]
  },

  {
    path: '/ordering',
    name: 'ordering',
    redirect: 'ordering/shop',
    component: () => import('layouts/OrderingLayout.vue'),
    children: [
      {
        path: 'shop',
        name: 'ordering-address',
        component: () => import('pages/ordering/Address.vue')
      },
      {
        path: 'delivery',
        name: 'ordering-delivery',
        component: () => import('pages/ordering/Delivery.vue')
      },
      // {
      //   path: 'payment',
      //   name: 'ordering-payment',
      //   component: () => import('pages/ordering/Payment.vue')
      // },
      {
        path: 'form',
        name: 'ordering-orderForm',
        component: () => import('pages/ordering/OrderForm.vue')
      },
      {
        path: 'confirmation',
        name: 'ordering-confirmation',
        component: () => import('pages/ordering/Confirmation')
      }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '*',
    component: () => import('pages/Error404.vue')
  }
]

export default routes
